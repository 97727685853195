import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './componentes/Home';
import BallestasSearchResult from './componentes/BallestasSearchResult';
import Checkout from './componentes/Checkout.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store.js';

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/ballestas" element={<Home />} />
    <Route path="/ballestas/search" element={<BallestasSearchResult />} />
    <Route path="/checkout/:id" element={<Checkout />} />
    <Route path="/restaurants" element={<h1>restaurantes</h1>} />
    <Route path="/hotels" element={<h1>hoteles</h1>} />
    <Route path="/activities" element={<h1>Actividades</h1>} />
  </Routes>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);