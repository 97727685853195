import React from 'react';
export default function  Characteristic (props){
    return (
        <div className="d-flex flex-row">
            <div className="d-flex align-items-center">
                <img src={props.url} className="app-caracteristicas-imagen col-3  rounded-circle" />
            </div>
            <div className="p-3">
                <h6 className="fw-bold text-darkslateblue">{props.title}</h6>
                <p className="text-muted">{props.subTitle}</p>
            </div>
        </div>
    )
}  