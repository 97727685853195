import React,{Component} from 'react';
import Searcher from './Searcher';
import Characteristic from './Characteristic';
export default class Home extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return (
            <div>
                <div className="bg-white">
                    <div className="d-flex justify-content-between">
                        <div className="fw-light p-2 d-flex align-items-center"><i className="fa-solid fa-bars fa-lg"></i></div>
                        <div className="fw-bold p-2 fs-3 d-flex align-items-center font-quicksand"><span className="text-teal">Paracas</span><span className="text-darkorange">.online</span></div>
                        <div className="fw-light p-2 d-flex align-items-center"><i className="fa-regular fa-user fa-lg"></i></div>
                    </div>
                </div>
                <div className="bg-warning">
                    <div className="px-2 pt-4 pb-4">
                        <br/>
                        <Searcher />
                        <br />
                    </div>
                </div>
                <div className="bg-white mt-4">
                    <div className="m-2">
                        <h6 className="text-center text-uppercase fs-6 pt-4 fw-bold">¿porque reservar con paracas.online?</h6>
                        <Characteristic url={'https://telesup.edu.pe/wp-content/uploads/2023/08/habitos-de-estudio-800x600.jpg'} title={'Información Confiable'} subTitle={'Datos precisos y verificados para tu visita. Planifica con confianza.'} />
                        <Characteristic url={'https://filestage.io/wp-content/uploads/2019/01/how-to-ask-for-feedback-2.png'} title={'Comentarios Reales'} subTitle={'Opiniones reales de viajeros para decisiones seguras. Evita sorpresas desagradables.'} />
                        <Characteristic url={'https://blogcomparasoftware-192fc.kxcdn.com/wp-content/uploads/2021/02/gestion-de-reclamos-de-clientes-1024x576.png'} title={'Seguridad Garantizada'} subTitle={'Protégete con información sobre regulaciones y denuncias. Disfruta con tranquilidad.'} />
                        <Characteristic url={'https://w7.pngwing.com/pngs/611/322/png-transparent-line-gift-logo-line-text-heart-logo.png'} title={'Ofertas Exclusivas'} subTitle={'Descuentos especiales para tu diversión. Ahorra mientras exploras Paracas.'} />
                    </div>
                </div>
            </div>
        )
    }
}