import React,{useState} from 'react';
import '../css/loading2.css';

export default function Loading2(props) {
  const m=props.msg===null ? "Espere por favor..." : props.msg;
  const [msg] = useState(m);
  return (
    <div className="loading2">
      <div className="loading2-content">
        <div className="d-flex justify-content-center align-items-center h-100">
            <div>
                <p className="loading2-text">{msg}</p>
                <div className="loading2-dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}
