import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {logOut,updateUser} from '../redux/userSlice';
import AlertComponent from './AlertComponent';
import { useParams } from 'react-router-dom';
export default function DeductFromCurrentBalanceComponent(props){
    const { id } = useParams();
    const [currentStep,setCurrentStep] = useState(props.currentStep);
    const [methodOfPaySelected,setMethodOfPaySelected] =useState(props.methodOfPaySelected);
    const [showAlert,setShowAlert] = useState(false); 
    const [alertMessage,setAlertMessage] = useState("");
    const [alertAcceptButton,setAlertAcceptButton] = useState();
    const [user, setUser] = useState({});
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(isAuthenticated){
        const url = `https://apis.gestionafacil.site/paracas.online/v1.0/account?token=${token}`;
            fetch(url)
            .then(response=>response.json())
            .then((responseData)=>{
                
                const r=responseData.response;
               
                if(!r.success){
                    dispatch(logOut());
                    setShowAlert(true);
                    setAlertMessage("Su token ha expirado");
                    setAlertAcceptButton(()=>{
                        window.location.href = window.location.origin;
                    });
                }else{
                    const tokenUpdated=r.token_updated;
                    if(r.user!=null){
                        setUser(r.user);
                        dispatch(updateUser({token:tokenUpdated}));
                    }

                }
            });
        }
    },[isAuthenticated, dispatch, token]);  

    return (

        <>
            <div className="bg-white p-2 mb-1 rounded-top">
                <i className="fa-solid fa-piggy-bank"></i>&nbsp;Pago desde tu saldo actual
            </div>
            <div className="bg-white px-2 pt-4 pb-2 mb-1">
                <div className="form-group mb-3">
                    <label className="fw-bold">Saldo Actual:</label>
                    <div className="form-control border text-end">{user.balanceCurrency} {parseFloat(user.balanceAmount).toFixed(2)}</div>
                </div>
                <div className="form-group mb-3">
                    <label className="fw-bold">Total a pagar:</label>
                    <div className="form-control border text-end">{user.balanceCurrency} {parseFloat(props.totalAmount).toFixed(2)}</div>
                </div>
                <div className="form-group mb-3">
                    <label className="fw-bold">Saldo a disponibilidad:</label>
                    <div className={`form-control border text-end ${user.balanceAmount-props.totalAmount>=0 ? 'text-success' : 'text-danger'}`}>{user.balanceCurrency} {parseFloat(user.balanceAmount-props.totalAmount).toFixed(2)}</div>
                </div>
            </div>
            <div className="bg-white px-2 pt-4 pb-2 mb-1 rounded-bottom d-flex justify-content-between">
                <div className="text-primary p-2"><i className="fa-solid fa-circle-info fa-lg"></i></div>
                <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</div>
            </div>
            <div className="d-flex justify-content-center mt-4 fixed-bottom">
                <button className={`btn btn-warning btn-lg w-100 px-5 ${user.balanceAmount-props.totalAmount>=0 ? '' : 'disabled'}`} onClick={()=>{
                    
                    const params=JSON.stringify({
                        methodOfPay:methodOfPaySelected,
                        stepCurrent:currentStep,
                        token:token});
                    console.log(params);
                    const url=`https://apis.gestionafacil.site/paracas.online/v1.0/sales/update/${id}`;
                    fetch(url,{
                        method: 'POST',
                        body: params,
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(responseData => {
                        const r=responseData.response;
                        if (r.success) {
                            const tokenUpdated=r.token_updated;
                            dispatch(updateUser({token:tokenUpdated}));
                            setCurrentStep(r.data.stepCurrent);
                            props.onUpdateCurrentStep(r.data.stepCurrent);
                            props.onClose();
                        } else {
                            alert('Error al insertar en la base de datos');
                        }
                    })
                    .catch(error => {
                        console.error('Error en la solicitud:', error);
                        alert('Error en la solicitud al servidor');
                    });
                }}>¡Pagar ahora!</button>
            </div>
            {showAlert && (<AlertComponent onAccept={alertAcceptButton} message={alertMessage} />)}
        </>
    )
}