import React,{useState,useEffect} from 'react';
import '../css/dots.css';
export default function PasswordTableComponent(props){
    const [password,setPassword] = useState("");
    const [activeDots, setActiveDots] = useState([false, false, false, false, false, false]);
    const [shuffledDigits, setShuffledDigits] = useState([]);

    useEffect(() => {
        const digits = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
        setShuffledDigits(digits);
    }, []);

    

    const shuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
    
    const handleDigitClick = (digit) => {
        if (password.length < 6) {
            const newPassword = password + digit;
            setPassword(newPassword);
            props.onUpdatePassword(newPassword);
            const updatedActiveDots = [...activeDots];
            updatedActiveDots[newPassword.length - 1] = true;
            setActiveDots(updatedActiveDots);
            if (newPassword.length === 6) {
                props.onUpdateIsPassComplete(true);
            } else {
                props.onUpdateIsPassComplete(false);
            }
        }
      };
    
    const handleDeleteClick = () => {
        if (password.length > 0) {
            const newPassword = password.slice(0, -1);
            setPassword(newPassword);
            props.onUpdatePassword(newPassword);
            const updatedActiveDots = [...activeDots];
            updatedActiveDots[newPassword.length] = false;
            setActiveDots(updatedActiveDots);
    
            // Deshabilitar el botón si la nueva longitud de la contraseña es 0
            props.onUpdateIsPassComplete(newPassword.length > 0);
            if (newPassword.length === 6) {
                props.onUpdateIsPassComplete(true);
            } else {
                props.onUpdateIsPassComplete(false);
            }
        }
    };
    return (
        <>
            <div className="d-flex flex-column">
                <div className="mt-1 mb-1 mx-2 d-flex justify-content-center">
                    <div>
                        {activeDots.map((isActive, index) => (
                            <div key={index} className={`dot-password ${isActive ? 'active' : ''}`}></div>
                        ))}
                    </div>
                </div>
                <div className="mt-3 mx-2 d-flex justify-content-center">
                    <div className="d-flex flex-wrap">
                        {shuffledDigits.map((digit, index) => (
                            <div key={index} className="col-4 d-grid gap-2">
                                <a className="btn btn-teal m-1"  onClick={() => handleDigitClick(digit)}>
                                {digit}
                                </a>
                            </div>
                        ))}
                        <div key="delete" className="col-8 d-grid gap-2">
                            <a className="btn btn-teal m-1" onClick={handleDeleteClick}>
                                <i className="fa-solid fa-delete-left"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}