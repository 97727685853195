import React, { useState, useEffect } from 'react';

export default function ToolbarSimpleComponent(props) {
   

    useEffect(() => {
    }, []);


    return (
        <div className="bg-teal">
            <div className="d-flex justify-content-start mx-4 pt-4 pb-4 text-white">
                <div className="d-flex align-items-center" onClick={() => { props.onClose() }}>
                    <i className="fa-solid fa-arrow-left fa-2x"></i>
                </div>
                <div className="d-flex align-items-center mx-4">
                    <span className="text-center fs-4">{props.title}</span>
                </div>
            </div>
        </div>
    );
}
