import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../redux/userSlice';
import Loading from './Loading';
import ToolbarSimpleComponent from './ToolbarSimpleComponent';
import '../css/full-modal.css';
import PurchasedServicesComponent from './PurchasedServicesComponent';
function ItemSale({item}){
    const [customer] = useState(item.customer);
    //const totalAmount = item.details.reduce((total, obj) => total + parseFloat(obj.totalToPay), 0).toFixed(2);
    return (
        <>
             <div className="m-2 bg-white">
                <div className="p-3 mx-1 mb-1 mt-1">
                    <div className="d-flex justify-content-between text-muted">
                        <small className="text-capitalize">{customer===null ? "" : customer.names.toLowerCase()} {customer===null ? "" : customer.surnames.toLowerCase()}</small>
                        <small>24.05.2023</small>
                    </div>
                    
                    <PurchasedServicesComponent details={item.details} />

                    
                </div>
             </div>
        </>
    )
}
export default function ManageServicesComponent(props){
    const [sales, setSales] = useState([]);
    const [error, setError] = useState(null);
    const [showLoading,setShowLoading] = useState(false);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();
   

    useEffect(()=>{
        setShowLoading(true);
        const url = `https://apis.gestionafacil.site/paracas.online/v1.0/sales?token=${token}&operation=getServiceInCareProcess`;
        fetch(url)
          .then(response => response.json())
          .then((responseData) => {
            const r = responseData.response;
            if (r.success) {
              const tokenUpdated = r.token_updated;
              dispatch(updateUser({ token: tokenUpdated }));
              setSales(r.data);
             
              setShowLoading(false);
            } else {
              setError("Error en la solicitud");
            }
          })
          .catch(error => {
            console.error("Error fetching data:", error);
            setError("Error de red");
          });
    },[])

    return(
        <>
            {showLoading && <Loading />}
            <div className="full-modal">
                <div className="full-modal-content bg-gainsboro">
                    <div className="sticky-top">
                        <ToolbarSimpleComponent className="fixed-top" title="Gestionar servicios" onClose={()=>{props.onClose()}} />
                    </div>
                    {sales.map((item,index)=>(
                        <ItemSale
                            key={index}
                            item={item}
                        />
                    ))}
                </div>
            </div>
            
        </>
    )
}