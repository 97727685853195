import React,{useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {logOut,updateUser} from '../redux/userSlice';
import Loading from './Loading';
import ToolbarSimpleComponent from './ToolbarSimpleComponent';
import '../css/full-modal.css';
function WalletItem(props){
    const [wallet] = useState(props.wallet);
    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                    <div className="d-flex flex-column">
                        <label className="text-black fw-bold">{wallet.description}</label>
                        <label className="text-muted">{wallet.operation_date}</label>
                    </div>
                    <div className="d-flex align-items-center">
                        <label className="text-danger text-uppercase fw-bold fs-6">{wallet.currency} {parseFloat(wallet.amount).toFixed(2)}</label>
                    </div>
            </div>  
        </>    
    );
}
export default function ManageWalletComponent(props){
    const dispatch = useDispatch();
    const [showLoading,setShowLoading] = useState(false);
    const token = useSelector((state) => state.user.token);
    const [wallets,setWallets] = useState([]);
    useEffect(()=>{
        const url=`https://apis.gestionafacil.site/paracas.online/v1.0/wallets?token=${token}`;
        fetch(url)
        .then(response=>response.json())
        .then((responseData)=>{
            setShowLoading(false);
            const r=responseData.response;
            
            if(!r.success){

                dispatch(logOut());
                /*setShowAlert(true);
                setAlertMessage("Su token ha expirado");
                setAlertAcceptButton(()=>{
                    window.location.href = window.location.origin;
                });*/

            }else{
                
                const tokenUpdated=r.token_updated;
                dispatch(updateUser({token:tokenUpdated}));
                setWallets(r.data);
            }
        })
        .catch(error => {

            //setShowAlert(true);
            //setAlertMessage(error);
            //setAlertAcceptButton(()=>{window.location.reload()});
            
        });
    },[])
    return(
        <>
           
            <div className="full-modal">
                <div className="full-modal-content bg-white">
                    <div className="sticky-top">
                        <ToolbarSimpleComponent className="fixed-top" title="Monedero" onClose={()=>{props.onClose()}} />
                    </div>
                    <p className="text-center text-danger fs-1 fw-bold mt-4 mb-0">PEN 0.00</p>
                    <p className="text-center mt-0 mb-4">Saldo disponible</p>
                    <div className="m-2 d-flex justify-content-between">
                        <button className="btn btn-primary w-100">Depositar</button>
                        <button className="btn btn-outline-primary w-100">Retirar</button>
                    </div>
                    <hr />
                    <div className="m-2">
                        <h3 className="text-primary mt-2">Movimientos</h3>
                        {
                            wallets.map((el)=>(
                                <WalletItem key={el.id} wallet={el} />
                            ))
                        }
                    </div>
                </div>


            </div>
            
        </>
    )
} 