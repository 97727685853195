import React,{useState,useEffect} from 'react';
import moment from 'moment';
import ProcessDetailsTrakingComponent from './ProcessDetailsTrakingComponent';
function ItemService({item,index}){

    const [destination,setDestination] =useState({});
    const [boarding,setBoarding] = useState({});
    const [showProcessDetailsComponent,setShowProcessDetailsComponent] = useState(false);
     
    useEffect(()=>{
        const url=`https://apis.gestionafacil.site/paracas.online/v1.0/departures/${item.id}`;
        fetch(url)
        .then(response=>response.json())
        .then((responseData)=>{
            const r=responseData.response;
            setDestination(r.data[0].destination);
            setBoarding(r.data[0].boarding);
           
        })
        .catch(error =>{

        })
    },[])

    return(
        <>
            <div className="mt-1 mb-1">
                <p className="mt-0 mb-0 text-black mt-1 fw-bold"> Excursion a {destination.denomination_long} con <span className="fw-bold text-uppercase">{item.business.name}</span></p>
                <div className="d-flex justify-content-start text-muted mx-1 mt-2">
                    <i className="fa-solid fa-user-group"></i>&nbsp;Pasajeros:&nbsp;{parseInt(item.paxAdults)+parseInt(item.paxChildren)}{item.paxBabies>0 ? '+'+item.paxBabies : ''}
                </div>
                <div className="d-flex justify-content-start text-muted mx-1">
                    <i className="fa-regular fa-calendar"></i>&nbsp;Salida:&nbsp;<span className="text-capitalize">{moment(item.startService).format('dddd DD MMM YYYY')}</span>
                </div>
                <div className="d-flex justify-content-start text-muted mx-1">
                    <i className="fa-solid fa-clock"></i>&nbsp;Hora:&nbsp;<span className="text-capitalize">{moment(item.startService).format('HH:mm a')}</span>
                </div>
                <div className="d-flex justify-content-end mt-4 mb-4">
                    <label className="text-danger">Total <span className="fw-bold">PEN {parseFloat(item.totalToPay).toFixed(2)}</span></label>
                </div>
                <div className="d-flex justify-content-between text-muted bg-gainsboro p-2 rounded mb-4" onClick={()=>{
                    setShowProcessDetailsComponent(true)
                }}>
                    <div className="d-flex align-items-start d-flex align-items-center fs-6">
                        <small className="fw-bold"><i className="fa-solid fa-circle-nodes"></i>&nbsp;ESTADO DEL SERVICIO&nbsp;-&nbsp;En espera</small>
                    </div>
                    <div className="d-flex align-items-center p-2">
                        <i className="fa-solid fa-chevron-right fa-lg"></i>
                    </div>
                </div>
            </div>
            {showProcessDetailsComponent && <ProcessDetailsTrakingComponent onClose={()=>{setShowProcessDetailsComponent(false)}} />}
        </>
    )
}
export default function PurchasedServicesComponent({details}){
    return(
        <>
            <p className="mt-2 mb-2 text-teal">Servicios adquiridos ({details.length})</p>
            {
                details.map((service,index)=>(
                    <ItemService key={index} item={service} index={index} />
                ))
            }
        </>
    )
}
