import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../redux/userSlice';
import '../css/loading-wave.css';

export default function BadgeComponent(props) {
  const [label, setLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setError(null);

    const url = `https://apis.gestionafacil.site/paracas.online/v1.0/sales?token=${token}&operation=getServiceInCareProcess`;
    fetch(url)
      .then(response => response.json())
      .then((responseData) => {
        const r = responseData.response;
        if (r.success) {
          const tokenUpdated = r.token_updated;
          dispatch(updateUser({ token: tokenUpdated }));
          if(r.pending==1){
            setLabel(r.pending + " pendiente");
          }else if(r.pending>1){
            setLabel(r.pending + " pendientes");
          }
          
        } else {
          setError("Error en la solicitud");
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setError("Error de red");
      })
      .finally(() => setLoading(false));
  }, [label]);

  return (
    <>
      {loading ? (
        <div className="loading-wave">
            <div className="dot-wave-container">
            <div className="dot-wave"></div>
            <div className="dot-wave"></div>
            <div className="dot-wave"></div>
            </div>
        </div>
      ) : error ? (
        <span className="badge text-bg-error">{error}</span>
      ) : (
        
            label!=="" && <span className="badge text-bg-dark">{label}</span>
            
      )}
    </>
  );
}