import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {logOut,updateUser} from '../redux/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import AlertComponent from './AlertComponent';
import moment from 'moment';
function BallestasItemReview({index,item}){
    const [destination,setDestination] = useState({});
    const [boarding,setBoarding] = useState({});
    const [business,setBusiness] = useState({});
    const [startService,setStartService] = useState();
    useEffect(()=>{
        
        const url=`https://apis.gestionafacil.site/paracas.online/v1.0/departures/${item.id}`;
        fetch(url)
        .then(response=>response.json())
        .then((responseData)=>{
            const r=responseData.response;
            setDestination(r.data[0].destination);
            setBoarding(r.data[0].boarding);
            setBusiness(r.data[0].business);
            setStartService(moment(item.startService));
        })
        .catch(error =>{

        })
    },[]);

    return(
        <>
            <h3>{index+1}.- Excursion a {destination.denomination_long}</h3>
            <div className="d-flex justify-content-start">
                <div className="d-flex flex-column col-6">
                    <p className="m-0">Fecha y horario</p>
                    <p className="m-0 fw-bold text-capitalize"><i className="fa-solid fa-calendar-days"></i>&nbsp;{moment(item.startService).format('DD MMM YYYY')}</p>
                    <p className="m-0"><i className="fa-regular fa-clock"></i>&nbsp;{moment(item.startService).format('hh:mm a')}</p>
                </div>
                <div className="d-flex flex-column col-6">
                    <p className="m-0">Duración y Personas</p>
                    <p className="m-0 fw-bold"><i className="fa-solid fa-hourglass-end"></i>&nbsp;02 horas</p>
                    <p className="m-0"><i className="fa-solid fa-people-group"></i>&nbsp;{item.paxBabies==0 ? parseInt(item.paxAdults)+parseInt(item.paxChildren) : parseInt(item.paxAdults)+parseInt(item.paxChildren)+'+'+item.paxBabies}</p>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-start">
                <div className="d-flex flex-column col-12">
                    <p className="m-0 fw-bold">LUGAR DE EMBARQUE:</p>
                    <div className="d-flex justify-content-between">
                        <div className="col-4 fs-6">Dirección</div>
                        <div className="mx-2 fs-6">:</div>
                        <div className="col-7 fs-6 text-capitalize">{boarding.denomination_long}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-4 fs-6">Ciudad</div>
                        <div className="mx-2 fs-6">:</div>
                        <div className="col-7 fs-6 text-capitalize">{boarding.city_long}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-4 fs-6">Páis</div>
                        <div className="mx-2 fs-6">:</div>
                        <div className="col-7 fs-6 text-capitalize">{boarding.country_long}</div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-start">
                <div className="d-flex flex-column col-12">
                    <p className="m-0 fw-bold">OPERADOR TURISTICO:</p>
                    <div className="d-flex justify-content-between">
                        <div className="col-4 fs-6">Razon social</div>
                        <div className="mx-2 fs-6">:</div>
                        <div className="col-7 fs-6 text-capitalize">{business.name}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-4 fs-6">Encargado</div>
                        <div className="mx-2 fs-6">:</div>
                        <div className="col-7 fs-6">Jhonatan Poll Boada Espinoza</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-4 fs-6">Celular</div>
                        <div className="mx-2 fs-6">:</div>
                        <div className="col-7 fs-6">+51946560423</div>
                    </div>
                </div>
            </div>
            <br />
        </>
    );
}
export default function ReviewStepComponent(props){
    const { id } = useParams();
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();
    const [sale,setSale] = useState({
        customer:null
    });
    
    const [showAlert,setShowAlert] = useState(false); 
    const [alertMessage,setAlertMessage] = useState("");
    const [alertAcceptButton,setAlertAcceptButton] = useState();

    useEffect(()=>{
        
        const url=`https://apis.gestionafacil.site/paracas.online/v1.0/sales/${id}?token=${token}`;
        console.log(url);
        fetch(url)
        .then(response=>response.json())
        .then((responseData)=>{
            const r=responseData.response;
            
            if(!r.success){
                dispatch(logOut());
                setShowAlert(true);
                setAlertMessage("Su token ha expirado");
                setAlertAcceptButton(()=>{
                    window.location.href = window.location.origin;
                });
            }else{
                
                const tokenUpdated=r.token_updated;
                dispatch(updateUser({token:tokenUpdated}));
                setSale(r.data);
                //setCustomer(r.data.customer);
            }
        })
        .catch(error => {

            setShowAlert(true);
            setAlertMessage(error);
            setAlertAcceptButton(()=>{window.location.reload()});
            
        });
    },[id, token, dispatch]);

    return(
        <>
            <div className="bg-white mt-4 mb-4 mx-2 p-3">
                <div className="d-flex justify-content-start">
                    <div className="d-flex flex-column col-12">
                        <p className="m-0 fw-bold">CLIENTE:</p>
                        
                        <div className="d-flex justify-content-between">
                            <div className="col-4 fs-6">Documento</div>
                            <div className="mx-2 fs-6">:</div>
                            <div className="col-7 fs-6 text-uppercase">{sale.customer && sale.customer.documentType} {sale.customer && sale.customer.documentNumber}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="col-4 fs-6">Nombres</div>
                            <div className="mx-2 fs-6">:</div>
                            <div className="col-7 fs-6 text-uppercase">{sale.customer && sale.customer.names} {sale.customer && sale.customer.surnames}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="col-4 fs-6">Nacionalidad</div>
                            <div className="mx-2 fs-6">:</div>
                            <div className="col-7 fs-6 text-uppercase">{sale.customer && sale.customer.nacionality}</div>
                        </div>
                    </div>
                </div>
            </div>  
            <div className="bg-white mt-4 mb-4 mx-2 p-3">
                <h3 className="fw-bold text-primary">SERVICIOS ADQUIRIDOS ({sale.details?.length || 0})</h3>
                {
                    sale.details?.map((item, index) =>(
                        <BallestasItemReview key={item.id || index} index={index} item={item} />
                    ))
                }
            </div>
            {showAlert && (<AlertComponent onAccept={alertAcceptButton()} message={alertMessage} />)}
        </>
    );
}