import React,{useState,useEffect} from 'react';
import '../css/bottom-sheet.css';
import { logIn } from '../redux/userSlice';
import { useDispatch } from 'react-redux';
import DocumentTypeListComponent from './DocumentTypeListComponent';
import NacionalitiesListComponent from './NacionalitiesListComponent';
import Loading from './Loading';
import Loading2 from './Loading2';
export default function LoginBottomSheetPersonalInformations(props){
    const dispatch = useDispatch();
    const [msgLoading,setMsgLoading] = useState("");
    const [documents,setDocuments] = useState([]);
    const [nacionalities,setNacionalities] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoading2,setIsLoading2] = useState(false);
    const [person,setPerson] = useState({
        id:0,
        documentType:null,
        documentNumber:"",
        names:"",
        surnames:"",
        nacionality:null,
        email:""
    });
    const [user,setUser] = useState(props.user);
    const [isDocumentTypeListVisible,setIsDocumentTypeListVisible] = useState(false);
    const [isNacionalitiesListVisible,setIsNacionalitiesListVisible] = useState(false);
    const updatePerson = (value) => {
        setPerson(value);
        user.person=value;
        setUser(user);
      };
    
    useEffect(() => {
            setIsLoading(true);
            const url="https://apis.gestionafacil.site/paracas.online/v1.0/persons/especialData";
            fetch(url)
            .then(res => res.json())
            .then(
                (responseData) => {
                    const res=responseData.response.data;
                    const documentType=res.documents.length===0 ? null : res.documents[0];
                    const filteredNacionality = res.nacionalities.filter(n => n.code === 'pe');
                    const nacionality = filteredNacionality.length === 0 ? null : filteredNacionality[0];
                    //updatePerson(prevPerson =>({...prevPerson,documentType:documentType,nacionality:nacionality}));
                    person.documentType=documentType;
                    person.nacionality=nacionality;
                    setPerson(person);
                    user.person=person;
                    setUser(user);
                    setDocuments(res.documents);
                    setNacionalities(res.nacionalities);
                    setIsLoading(false);
                }
            );
    },[]);
    const setOnClose=()=>{
        props.onClose();
    }
    const handleSignIn = () => {
        setMsgLoading("Aperturando cuenta, espere por favor...");
        setIsLoading2(true);
        const url="https://apis.gestionafacil.site/paracas.online/v1.0/account/create";
        fetch(url,{
            method:'POST',
            body: JSON.stringify({
                cellPhoneNumber:user.cellPhoneNumber,
                cellPhonePostalId: user.cellPhonePostal,
                cellPhoneValidated: user.cellPhoneValidated,
                password: user.password,
                person: {
                    id: user.person.id,
                    email: user.person.email,
                    names: user.person.names,
                    surnames: user.person.surnames,
                    documentNumber: user.person.documentNumber,
                    documentTypeId: user.person.documentType.id,
                    nacionalityCode: user.person.nacionality.code
                }
            })
        })
        .then(res => res.json())
        .then(
            (responseData) => {
                
                const res=responseData.response;
               
                dispatch(logIn({token:res.token}));
                setIsLoading2(false);
                setOnClose();
                            
            }
        );
    }
    return (
        <>
            <div className="backdrop">
                <div className="bottom-sheet">
                <div className="d-flex justify-content-between">
                    <div>&nbsp;</div>
                    <p className="text-center fw-bold fs-6 col-10 text-uppercase p-2">¡Ya casi terminamos!</p>
                    <div className="p-2" onClick={setOnClose}><i className="fa-solid fa-x"></i></div>
                </div>
                <div className="d-flex flex-column">
                    <div className="form-group">
                        <div className="d-flex justify-content-center mb-1">
                            <div className="d-flex justify-content-between border">
                                <div className="col-4 d-flex align-items-center justify-content-center">
                                    <div className="d-flex justify-content-start"><span className="px-4 pt-1 pb-1 text-uppercase" onClick={()=>{setIsDocumentTypeListVisible(true)}}>{person.documentType===null ? "" : person.documentType.denomination_short}&nbsp;</span><i className="fa-solid fa-caret-down"></i></div>
                                </div>
                                <input type="text" autoFocus placeholder={"N° documento"} onChange={(e) => {person.documentNumber=e.target.value;}} className="w-100" />
                            </div> 
                            <div className="d-flex align-items-center justify-content-center m-1">
                                <a className="btn btn-primary pt-2 pb-2" onClick={()=>{
                                    setMsgLoading("Buscando informacion, espere por favor...");
                                    setIsLoading2(true);
                                    fetch("https://apis.gestionafacil.site/paracas.online/v1.0/persons/search",{
                                        method:'POST',
                                        body: JSON.stringify({
                                            documentType:person.documentType.denomination_short,
                                            documentNumber:person.documentNumber
                                        })
                                    })
                                    .then(response => response.json())
                                    .then(responseData => {
                                        setIsLoading2(false);
                                        
                                        const result=responseData.response;
                                        
                                        if(!result.success){
                                            alert(result.msg);
                                            return;
                                        }
                                        
                                        const filteredNacionality = nacionalities.filter(n => n.code === (result.data===null ? "es" : result.data.nacionality_code));
                                        const nacionality = filteredNacionality.length === 0 ? null : filteredNacionality[0];
                                        
                                        person.id=result.data===null ? 0 : result.data.id;
                                        person.names=result.data===null ? "" : result.data.names;
                                        person.surnames=result.data===null ? "" : result.data.surnames;
                                        person.nacionality=nacionality;
                                        person.email=result.data===null ? "" : result.data.email;
                                        setPerson(person);
                                        user.person=person;
                                        setUser(user);
                                    });
                                }}>Buscar</a>
                            </div> 
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" 
                        onChange={(e) => {
                            person.names=e.target.value;
                            user.person=person;
                            }
                        }
                        placeholder={('Nombres')}  defaultValue={person.names} readOnly={true} className="form-control form-control-lg border" />
                    </div>
                    <div className="form-group">
                        <input type="text" onChange={(e) =>{
                                person.surnames=e.target.value;
                                user.person=person;
                            }
                        } placeholder={('Apellidos')} defaultValue={person.surnames} readOnly={true} className="pt-1 pb-1 form-control form-control-lg border" />
                    </div>    
                    <div className="border mb-2" onClick={()=>{setIsNacionalitiesListVisible(true)}}>
                        <div className="">
                            <div className="col-12 d-flex justify-content-between px-2 pt-3 pb-3"><div className="text-uppercase">{person.nacionality===null ? "" : person.nacionality.name}&nbsp;</div><div><i className="fa-solid fa-caret-down"></i></div></div>
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" onChange={(e) =>{
                                person.email=e.target.value;
                                user.person=person;
                            }
                        } placeholder={('Correo electronico')} defaultValue={person.email} className="form-control form-control-lg border" />
                    </div>
                    <div className="mt-1">
                        <a className="btn btn-teal w-100" onClick={handleSignIn}>Finalizar</a>
                    </div>
                </div>
                </div>
            </div>
            {isLoading && <Loading />}
            {isLoading2 && <Loading2 msg={msgLoading} />}
            {isDocumentTypeListVisible && <DocumentTypeListComponent data={documents} person={person} onUpdatePerson={(value)=>{
                setPerson(value);
                setIsDocumentTypeListVisible(false);
            }} />}
            {isNacionalitiesListVisible && <NacionalitiesListComponent data={nacionalities} person={person} onUpdatePerson={(value)=>{
                setPerson(value);
                setIsNacionalitiesListVisible(false);
            }} />}
        </>
    );
}