
import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';

import '../css/cart-shopping.css';
export default function FloatingCart(props){
    
    const cartItems = useSelector((state) => state.cart.items);
    const [cartCount, setCartCount] = useState(cartItems?.length || 0);

    useEffect(() => {
        setCartCount(cartItems?.length || 0);
    }, [cartItems]);

    return (
      <>
        <button className="cart-button" onClick={()=>{props.onUpdateIsCartShoppingComponentOpen(true);}}>
            <i className="fa-solid fa-cart-shopping"></i>
            <div className="cart-counter">
                {cartCount}
            </div>
        </button>
      </>
    );
  };
  