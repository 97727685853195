import React,{useState} from 'react';
import '../css/bottom-sheet.css';
import Postals from './Postals';
export default function LoginBottomSheetSignIn(props){
    const [user,setUser] = useState({
        cellPhonePostal:{alpha2Code: "pe",name: "Peru",callingCodes: 51},
        cellPhoneNumber:"",
        password:"",
    });
    const [isPostalsComponentOpen,setIsPostalsComponentsOpen] = useState(false);
    const openSendCode = () =>{
        props.onUpdateIsLoginBottomSheetSendCode(true);
        props.onClose();
    }
    return (
        <>
            <div className="backdrop">
                <div className="bottom-sheet">
                    <div className="d-flex justify-content-between">
                        <div>&nbsp;</div>
                        <p className="text-center fw-bold fs-6 col-10 text-uppercase p-2">Inicia sesión</p>
                        <div className="p-2" onClick={() => props.onClose()}><i className="fa-solid fa-x fa-lg"></i></div>
                    </div>
                    <div className="d-flex flex-column mt-2">
                        <div className="form-group d-flex justify-content-between border">
                            <div className="col-4 d-flex align-items-center justify-content-center" onClick={()=>{
                                setIsPostalsComponentsOpen(true);
                            }}>
                                <div className="d-flex justify-content-start"><span>+{user.cellPhonePostal.callingCodes}&nbsp;</span><i className="fa-solid fa-caret-down"></i></div>
                            </div>
                            <input type="text"  autoFocus placeholder={"Número de celular"} onChange={(e) => {user.cellPhoneNumber=e.target.value;}} defaultValue={user.cellPhoneNumber} className="w-100" />  
                        </div>
                        <div className="mt-3">
                            <button className="btn btn-success w-100" onClick={()=>{
                               const url="https://apis.gestionafacil.site/paracas.online/v1.0/account?operation=validateCellphone&postalId="+user.cellPhonePostal.callingCodes+"&number="+user.cellPhoneNumber;
                               fetch(url)
                               .then(response => response.json())
                               .then(responseData =>{
                                    const isRegistered=responseData.response.isRegistered;
                                    if(isRegistered){
                                        props.onUpdateUser(user);
                                        props.onUpdateIsLoginBottomSheetPasswordTable(true);
                                        props.onClose();
                                    }else{
                                        alert("no esta registrado");
                                    }
                               });
                            }}>Entrar</button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-4 mb-4">
                        <hr className="col-5" />
                        <span>o</span>
                        <hr className="col-5" />
                    </div>
                    <div className="mt-2">
                        <a className="btn btn-outline-dark w-100" onClick={openSendCode}>Crear cuenta nueva</a>
                    </div>
                </div>
            </div>
           
            {isPostalsComponentOpen && <Postals onClose={()=>{setIsPostalsComponentsOpen(false)}} onUpdatePostal={(postalData) => {
                user.cellPhonePostal=postalData;
                setUser(user);
                setIsPostalsComponentsOpen(false);
            }}/>}
           

        </>

    );
}