import React,{useState,useEffect} from 'react';
import Loading from './Loading';
import ToolbarSimpleComponent from './ToolbarSimpleComponent';
import '../css/full-modal.css';
import '../css/step-vertical.css';

export default function ProcessDetailsTrakingComponent(props){
    const [steps] = useState([
        { id: 1, title: "titulo 1", subtitle: "Asegúrate de completar el paso 1" },
        { id: 2, title: "titulo 2", subtitle: "Asegúrate de completar el paso 2" },
        { id: 3, title: "titulo 3", subtitle: "Asegúrate de completar el paso 3" },
        { id: 4, title: "titulo 4", subtitle: "Asegúrate de completar el paso 4" },
        { id: 5, title: "titulo 5", subtitle: "Asegúrate de completar el paso 5" },
        { id: 6, title: "titulo 6", subtitle: "Asegúrate de completar el paso 6" },
        { id: 7, title: "titulo 7", subtitle: "Asegúrate de completar el paso 7" },
        { id: 8, title: "titulo 8", subtitle: "Asegúrate de completar el paso 8" },
        { id: 9, title: "titulo 9", subtitle: "Asegúrate de completar el paso 9" },
        { id: 10, title: "titulo 10", subtitle: "Asegúrate de completar el paso 10" },
      ]);

    return(
        <>
            <div className="full-modal">
                <div className="full-modal-content bg-white">
                    <div className="sticky-top">
                        <ToolbarSimpleComponent className="fixed-top" title="Itinerario" onClose={()=>{props.onClose()}} />
                    </div>
                    
                    <div class="wrapper mx-1 mt-3">
                        <ol class="c-timeline">
                            <li class="c-timeline__item">
                            <div class="c-timeline__content">
                                <h3 class="c-timeline__title fs-6">Iniciado</h3>
                                <p class="c-timeline__desc">Recepcion en estacion de bus o traslado desde hotel en paracas a embarque elegido</p>
                            </div>
                            <time>Sabado 07:46:51 (Lima/Peru)</time>
                            </li>
                            <li class="c-timeline__item">
                            <div class="c-timeline__content">
                                <h3 class="c-timeline__title fs-6">Completado</h3>
                                <p class="c-timeline__desc">Recepcion en estacion de bus o traslado desde hotel en paracas a embarque elegido</p>
                            </div>
                            <time>Sabado 07:54:12 (Lima/Peru)</time>
                            </li>
                           
                            <li class="c-timeline__item">
                            <div class="c-timeline__content">
                                <h3 class="c-timeline__title fs-6">Iniciado</h3>
                                <p class="c-timeline__desc">Tour a las islas ballestas</p>
                            </div>
                            <time>Sabado 08:16:45 (Lima/Peru)</time>
                            </li>
                            <li class="c-timeline__item">
                            <div class="c-timeline__content">
                                <h3 class="c-timeline__title fs-6">Completado</h3>
                                <p class="c-timeline__desc">Tour a las islas ballestas </p>
                            </div>
                            <time>Sabado 09:47:45 (Lima/Peru)</time>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    )
}
