import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { useSelector, useDispatch } from 'react-redux';
import {logOut,updateUser} from '../redux/userSlice';
import MyAccountComponent from './MyAccountComponent';

export default function BallestasSearchResultToolbar(props) {
    const [user, setUser] = useState({});
    const [date] = useState(moment(props.date));
    const [isFloating, setIsFloating] = useState(false);
    const [isMyAccountComponentOpen, setMyAccountComponentOpen] = useState(false);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = useSelector((state) => state.user.token);
    
    const dispatch = useDispatch();
    const isMounted = useRef(true);

    useEffect(() => {
        if(token!==null){
            const url = `https://apis.gestionafacil.site/paracas.online/v1.0/account?token=${token}`;
            fetch(url)
            .then(response=>response.json())
            .then((responseData)=>{
                const r=responseData.response;
                if(!r.success){
                    dispatch(logOut());
                    window.location.reload();
                }else{
                    const tokenUpdated=r.token_updated;
                    setUser(r.user);
                    dispatch(updateUser({token:tokenUpdated}));
                }
            });
        }
        
        const handleScroll = () => {
        const scrollY = window.scrollY || window.pageYOffset;
        const referencePosition = 100;
        setIsFloating(scrollY > referencePosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        isMounted.current = false;
        window.removeEventListener('scroll', handleScroll);
        };
    }, [isAuthenticated, dispatch, token]);

    const toolbarStyle = {
        position: isFloating ? 'fixed' : 'static',
        top: isFloating ? 0 : 'auto',
        width: '100%',
        zIndex: isFloating ? 1 : 'auto',
    };
    return (
        <>  
            {isMyAccountComponentOpen && <MyAccountComponent onClose={()=>{
                setMyAccountComponentOpen(false);
            }} />}
            <div className="bg-teal" style={toolbarStyle}>
                <div className="d-flex justify-content-between mx-2 pt-2 pb-1">
                    <div className="d-flex align-items-center text-white">&nbsp;</div>
                    <div className="d-flex align-items-center"><span className="fs-1 text-white text-center fw-bold font-quicksand">Paracas<span className="text-darkorange">.online</span></span></div>
                    <div className="d-flex align-items-center">
                        {!isAuthenticated ? <div className="rounded-circle border border-2 px-2 pt-1 pb-1" onClick={()=>{props.onOpenLoginBottomSheetSignIn(true)}}><i className="fa-regular fa-user fa-lg text-white"></i></div> : <img src={user.img} className="img-login-xs rounded-circle" onClick={()=>{setMyAccountComponentOpen(true)}} />}
                    </div>
                </div>
                <div className="pb-2 pt-1">
                    <div className="d-flex justify-content-between mx-2 badge text-bg-light">
                        <div className="mt-2">
                            <p className="text-teal fs-6 m-0 text-uppercase"><small className="m-0"><i className="fa-solid fa-building"></i>&nbsp;{props.boarding} - <i className="fa-solid fa-location-dot"></i>&nbsp;{props.destination}</small></p>
                            <p className="text-muted text-uppercase fs-6 m-0 pt-1"><small className="m-0">{date.format('ddd')} {date.format('D')}, {date.format('MMM')} {date.format('YYYY')}</small></p>
                        </div>
                        <div className="vr"></div>
                        <div className="d-flex d-flex justify-content-center align-items-center mt-2 mx-2">
                            <p className="fs-1 text-muted"><i className="fa-solid fa-people-group"></i>&nbsp;</p>
                            <p className="fs-1 text-muted">{parseInt(props.adults)+parseInt(props.children)+parseInt(props.babies)}pers.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}