import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {logOut,updateUser} from '../redux/userSlice';
function MethodOfPayItem(props){
    return (
        <>
          <div className={`p-2 bg-white d-flex justify-content-between mb-1`} onClick={props.onClick}>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center">
                <div className="p-2">
                  <i className={`fa-solid ${props.icon} fa-lg`}></i>
                </div>
              </div>
              <div className={props.subtitle.length !== 0 ? 'd-flex flex-column d-flex align-items-center' : 'd-flex align-items-center'}>
                <div className="fw-bold">{props.title}</div>
                {props.subtitle.length !== 0 && <div className="d-flex justify-content-start text-muted">{props.subtitle}</div>}
              </div>
            </div>
            <div className={`d-flex align-items-center text-success ${props.checked ? '' : 'd-none'}`}>
                <i className="fa-solid fa-check"></i>
            </div>
          </div>
        </>
      );
}
export default function MethodOfPayStepComponent(props){
   
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch(); 
    const [user,setUser] = useState({});
    const [showAlert,setShowAlert] = useState(false); 
    const [methodsOfPays] = useState([
        { code: 1, title: "Descontar desde tu saldo", subtitle: "Tu saldo actual es:", checked: true, icon: "fa-piggy-bank" },
        ]);
    //  { code: 2, title: "Congelar precio y pagar despues", subtitle: "", checked: false, icon: "fa-money-bill-transfer" },
    //  { code: 3, title: "Pagar con tarjeta de credito", subtitle:"Hasta en 12 cuotas", checked:false, icon:"fa-credit-card"}
    //  { code: 2, title: "Pago en efectivo", subtitle: "", checked: false, icon: "fa-money-check-dollar" },    
    //  { code: 4, title: "Pagar con Yape o Plin", subtitle: "", checked: false, icon: "fa-wallet" },

    useEffect(()=>{
        const url = `https://apis.gestionafacil.site/paracas.online/v1.0/account?token=${token}`;
        fetch(url)
        .then(response=>response.json())
        .then((responseData)=>{
            const r=responseData.response;  
            if(!r.success){
                dispatch(logOut());
                setShowAlert(true);
                window.location.href = window.location.origin;
            }else{
                const tokenUpdated=r.token_updated;
                setUser(r.user);
                for(let i=0;i<methodsOfPays.length;i++){
                    if(methodsOfPays[i].code==1){
                        methodsOfPays[i].subtitle="Tu saldo actual es: "+user.balanceCurrency+" "+parseFloat(user.balanceAmount).toFixed(2);
                    }
                }
                dispatch(updateUser({token:tokenUpdated}));
            }
        });
    },[user]);
    const handleMethodClick = (code) => {
        for(let i=0;i<methodsOfPays.length;i++){
            if(code===methodsOfPays[i].code){
                methodsOfPays[i].checked=true;
                props.onMethodOfPaySelected(methodsOfPays[i].code);
            }else{
                methodsOfPays[i].checked=false;
            }
        }
    };
    return (
        <>
            <div className="pt-4 pb-4 px-2">
                <h3>¿Como deseas pagar?</h3>
                <div className="d-flex flex-column mb-3">
                    {
                        methodsOfPays.map((el)=>(
                            <MethodOfPayItem 
                            key={el.code} 
                            icon={el.icon} 
                            checked={el.checked}
                            title={el.title} 
                            subtitle={el.subtitle}
                            onClick={() => handleMethodClick(el.code)}
                            /> 
                        ))
                    }
                </div>
            </div>
        </>
    );
}