import React from 'react';
import '../css/loading.css';
export default function Loading(props){
    
    return (
        <>
            <div className="loading">
                <div className="loading-content">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div>
                            <p className="fs-1 text-teal fw-bold font-quicksand">Paracas<span className="text-darkorange">.online</span></p>
                            <div className="loading-dots">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}