import React,{Component} from 'react';
import Loading from './Loading';
import '../css/postal.css';
function Postal(props){
    return(
        <>
            <div onClick={props.onClick}>
                <div  className="m-2 pt-1 pb-1 text-capitalize text-muted fs-6 d-flex justify-content-between" ><span>{props.name}</span><span>+{props.callingCodes}</span></div>
                <hr />
            </div>
            
        </> 
    );
}
export default class Postals extends Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            loading:false,
        }
    }
    closeModal = () => {
        this.props.onClose(); 
    }

    
    
    componentDidMount(){
        this.setState({loading:true});
        fetch("https://apis.gestionafacil.site/paracas.online/v1.0/postals")
        .then(res=>res.json())
        .then(
            (json) => {
                this.setState({
                    items:json.response.data,
                    loading:false,
                });
            }
        )
    }
    render(){
        const {items} =this.state;
        return (
            <>
                {this.state.loading && <Loading />}
                <div className="postal">
                    <div className="postal-content">
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-start shadow-sm pt-2 pb-2 bg-body-tertiary rounded bg-white">
                                <div className="p-2 d-flex align-items-center" onClick={this.closeModal}><i className="fa-solid fa-arrow-left fa-2x color-yellow"></i></div>
                                <div className="p-2 fw-bold d-flex align-items-center">Selecciona un codigo postal</div>
                            </div>
                            <div className="pt-3 bg-white">
                                {items.map((el)=>(
                                    <Postal
                                        key={el.alpha2Code}
                                        name={el.name}
                                        callingCodes={el.callingCodes}
                                        onClick={()=>this.props.onUpdatePostal({
                                            alpha2Code:el.alpha2Code,
                                            name:el.name,
                                            callingCodes:el.callingCodes
                                        })}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) 
    }
}
