import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';  // Cambia a useNavigate
import {logOut,updateUser} from '../redux/userSlice';
import { clearCart } from '../redux/cartSlice';
import AlertComponent from './AlertComponent';
export default function TotalToPayToolbarComponent(props){
    const [showAlert,setShowAlert] = useState(false); 
    const isAuthenticated = useSelector((state) =>state.user.isAuthenticated);
    const token = useSelector ((state) => state.user.token);
    const dispatch=useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-white border-top border-2 border-dark">
                <div className="d-flex justify-content-between m-4">
                    <div className="">
                        <p className="mb-0">PEN&nbsp;<span className="fs-1 fw-bold">{parseFloat(props.totalAmount).toFixed(2)}</span></p>
                        <p className="mt-0">Total a pagar</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="btn btn-danger btn-lg" onClick={()=>{
                            const url = `https://apis.gestionafacil.site/paracas.online/v1.0/account?token=${token}`;
                            fetch(url)
                            .then(response=>response.json())
                            .then((responseData)=>{
                                const r=responseData.response;
                                if(!r.success){
                                    dispatch(logOut());
                                    setShowAlert(true);
                                }else{
                                    const tokenUpdated=r.token_updated;
                                    dispatch(updateUser({token:tokenUpdated}));
                                    if(isAuthenticated){  
                                        const url = 'https://apis.gestionafacil.site/paracas.online/v1.0/sales';
                                        
                                        fetch(url, {
                                                method: 'POST',
                                                body: JSON.stringify({salesDetails:cartItems,token:token}),
                                            })
                                            .then(response => {
                                                if (!response.ok) {
                                                    throw new Error(`HTTP error! Status: ${response.status}`);
                                                }
                                                return response.json();
                                            })
                                            .then(responseData => {
                                                const r=responseData.response;
                                                if (r.success) {
                                                    const tokenUpdated=r.token_updated;
                                                    dispatch(updateUser({token:tokenUpdated}));
                                                    dispatch(clearCart());
                                                    navigate('/checkout/'+r.data.id);
                                                } else {
                                                    alert('Error al insertar en la base de datos');
                                                }
                                            })
                                            .catch(error => {
                                                console.error('Error en la solicitud:', error);
                                                alert('Error en la solicitud al servidor');
                                            });
                                    }else{
                                        alert("no esta conectado, tiene que conectarse");
                                    }
                                }
                            });
                            
                        }}>Comprar</button>
                    </div>
                </div>
            </div>
            {showAlert && (<AlertComponent onAccept={()=>{ window.location.reload();}} message="Su sesión ha expirado" />)}
        </>
    )
}