import React,{useState} from 'react'
import '../css/modal.css';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

export default function Modal(props){
    const [value, onChange] = useState(moment(props.selectedDate));
    const minDate = moment().toDate();
    return (
        <>
            <div className="modal">
                <div className="modal-container">
                    <Calendar onChange={(date) => {
                        onChange(date);
                        props.onUpdateDate(date);
                        }}
                        value={value}
                        minDate={minDate}
                     />
                    <button className="modal-close-button hover fw-lighter" onClick={props.onClose} ><i className="fa-solid fa-x fa-2x"></i></button>
                </div>
            </div>
        </>
    );
} 