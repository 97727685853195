import React,{useState} from 'react';

import '../css/full-modal.css';
import DeductFromCurrentBalanceComponent from './DeductFromCurrentBalanceComponent';
export default function MethodOfPayContent(props){
    const [methodOfPaySelected,setMethodOfPaySelected] = useState(props.methodOfPaySelected);
    const onClose=()=>{
        props.onIsFullModalOpen(false);
        props.onClose();
    }
    return (
        <>  
            <div className="full-modal">
                <div className="full-modal-content bg-gainsboro">
                    <div className="d-flex flex-column m-2">
                        <div className="d-flex justify-content-between pt-2 pb-2">
                            <div></div>
                            <div className="text-black" onClick={onClose}><i className="fa-solid fa-x fa-2x"></i></div>
                        </div>
                        <div>
                            {props.methodOfPaySelected===1 && <DeductFromCurrentBalanceComponent onClose={onClose} onUpdateCurrentStep={props.onUpdateCurrentStep} currentStep={props.currentStep} methodOfPaySelected={methodOfPaySelected} totalAmount={props.totalAmount}/>}
                        </div>
                    </div>
                </div>
            </div>
            
            
        </>
    );
}