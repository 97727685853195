import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { removeFromCart,clearCart } from '../redux/cartSlice';
import ToolbarSimpleComponent from './ToolbarSimpleComponent';
import TotalToPayToolbarComponent from './TotalToPayToolbarComponent';
import '../css/total-to-pay-toolbar.css';
function ItemCart({item, onRemoveItem,onClose}){
    const pax = item.pax || {};
    const business=item.business || {};
    const boarding=item.boarding || {};
    const destination=item.destination || {};
    const cartItems = useSelector((state) => state.cart.items);
    const handleRemove = () => {
        onRemoveItem(item.id);
        if(cartItems.length==1){
            onClose();
        }
    };
    return(
        <>
            <div className="m-2 bg-white">
                <div className="d-flex justify-content-center p-3 mx-1 mb-1 mt-1">
                    <div className="col-12">
                        <div className="d-flex justify-content-start">
                            <h1 className="fs-5 fw-bold text-uppercase text-teal m-0">{business.name}</h1>
                        </div>
                        <div className="d-flex justify-content-start">
                            <span className="badge text-bg-warning">{business.rating}</span>&nbsp;<span className="text-warning">{business.rating_str}</span>&nbsp;<span className="text-muted">- 1,1150 comentarios</span> 
                        </div>
                        <div>
                            <p className="text-teal m-0 pt-2"><i className="fa-solid fa-clock"></i>&nbsp;<span className="text-capitalize"><span className="fw-bold">Salida:&nbsp;</span>{moment(item.departureDate).format('MMM DD, YYYY')}</span><span className="fw-bold">&nbsp;Hora:&nbsp;</span>{moment(item.departureDate).format('hh:mm a')}</p>
                            <p className="text-teal m-0"><i className="fa-solid fa-hourglass-end"></i>&nbsp;<span className="fw-bold">Duración:&nbsp;</span>{"02 horas aprox."}</p>
                            <p className="text-muted m-0 pt-1 text-capitalize"><i className="fa-solid fa-building"></i>&nbsp;<span className="fw-bold">Embarque:&nbsp;</span>{boarding.denomination_long}, {boarding.city_long}, {boarding.country_long}</p>
                            <p className="text-muted m-0 pt-1 text-capitalize"><i className="fa-solid fa-location-dot"></i>&nbsp;<span className="fw-bold">Destino:&nbsp;</span>{destination.denomination_long}, {destination.city_long}, {destination.country_long}</p>
                            <p className="text-muted m-0 pt-1 text-capitalize"><i className="fa-solid fa-people-group"></i>&nbsp;<span className="fw-bold">Total pasajeros:&nbsp;</span><span className="fw-bold text-muted">&nbsp;{parseInt(pax.adults)+parseInt(pax.children)}{pax.babies>0 ? '+'+pax.babies : ''}</span></p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mx-1 p-3">
                    <div className="text-uppercase fs-1 text-teal fw-bold">{item.currency}&nbsp;{parseFloat(item.totalToPay).toFixed(2)}</div>
                </div>
                <hr />
                <div className="d-flex justify-content-center p-2 ">
                    <div>
                        <button className="btn btn-outline-dark btn-lg m-1">Ver detalle</button>
                        <button className="btn btn-outline-dark btn-lg m-1">Editar</button>
                        <button className="btn btn-outline-dark btn-lg m-1" onClick={handleRemove}>Quitar</button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default function CartShoppingComponent(props){
    const cartItems = useSelector((state) => state.cart.items);
    const dispatch = useDispatch();
    const totalAmount = cartItems.reduce((total, item) => total + parseFloat(item.totalToPay), 0).toFixed(2);
    const handleRemoveItem = (itemId) => {
        dispatch(removeFromCart({ id: itemId }));
    };

    useEffect(() => {
    },[cartItems]);

    return(
        <>
            <div className="full-modal">
                <div className="full-modal-content bg-gainsboro">
                    <div className="sticky-top">
                    <ToolbarSimpleComponent className="fixed-top" title="Detalle de la compra" onClose={()=>{props.onClose()}} />
                    </div>
                    <div className="d-flex justify-content-between d-flex align-items-center mx-3">
                        <span className="mt-1 fw-bold">Carrito({cartItems.length})</span>
                        <a className="btn btn-link" onClick={()=>{
                            dispatch(clearCart());
                            props.onClose();
                        }}>Vaciar carrito</a>
                    </div>
                    {cartItems.map((item,index)=>(
                        <ItemCart
                            key={index}
                            item={item}
                            onRemoveItem={handleRemoveItem}
                            onClose={()=>{props.onClose()}}
                        />
                    ))}
                    <div className="sticky-bottom">
                        <TotalToPayToolbarComponent totalAmount={totalAmount} />
                    </div>
                    
                </div>
            </div>
        </>
    );
}