import React,{Component} from 'react';
import Loading from './Loading';
import '../css/boarding.css';
function Boarding(props){
    return (
        <>
            <div onClick={props.onClick} >
                <div className="m-2 pt-1 px-2 pb-1 text-capitalize text-muted fs-6 d-flex align-items-center" ><i className="fw-bold text-black fa-solid fa-building  fa-2x"></i>&nbsp;<small>{props.denomination}, {props.city}, {props.country}</small></div>
                <hr/>
            </div>
        </>
    );
}
export default class Boardings extends Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            loading:false,
        }
    }
    closeModal = () => {
        this.props.onClose(); 
    }

    
    
    componentDidMount(){
        this.setState({loading:true});
        fetch("https://apis.gestionafacil.site/paracas.online/v1.0/boardings")
        .then(res=>res.json())
        .then(
            (json) => {
                this.setState({
                    items:json.response.data,
                    loading:false,
                });
            }
        )
    }
    render(){
        const {items} =this.state;
        return (
            <>
                {this.state.loading && <Loading />}
                <div className="boarding">
                    <div className="boarding-content">
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-start shadow-sm pt-2 pb-2 bg-body-tertiary rounded bg-white">
                                <div className="p-2 d-flex align-items-center" onClick={this.closeModal}><i className="fa-solid fa-arrow-left fa-2x color-yellow"></i></div>
                                <div className="p-2 fw-bold d-flex align-items-center">Selecciona el lugar de embarque</div>
                            </div>
                            <div className="pt-3">
                                {items.map((el)=>(
                                    <Boarding
                                        key={el.id}
                                        denomination={el.denomination_long}
                                        city={el.city_long}
                                        country={el.country_long}
                                        onClick={() => this.props.onUpdateBoarding({
                                            id: el.id,
                                            denomination_short:el.denomination_short,
                                            denomination_long: el.denomination_long,
                                            city_long: el.city_long,
                                            country_long: el.country_long,
                                            })}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) 
    }
}

