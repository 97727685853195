import React,{useState} from 'react';
import '../css/full-modal.css';
function NacionalitiesItemComponent(props){
    
    return (
        <>
            <div>
                <div onClick={props.onClick} className="m-2 pt-1 px-2 pb-1 text-capitalize text-muted fs-6 d-flex align-items-center" ><i className="fw-bold text-black fa-solid fa-earth-americas fa-2x"></i>&nbsp;&nbsp;<small>&nbsp;&nbsp;{props.name}</small></div>
                <hr/>
            </div>
        </>
    );
}
export default function NacionalitiesListComponent(props){
    const [person,setPerson] = useState(props.person);
    const [items] = useState(props.data);
    return (
        <>
            <div className="full-modal">
                <div className="full-modal-content">
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-start shadow-sm pt-2 pb-2 bg-body-tertiary rounded bg-white">
                            <div className="p-2 d-flex align-items-center"><i className="fa-solid fa-arrow-left fa-2x color-yellow"></i></div>
                            <div className="p-2 fw-bold d-flex align-items-center">Selecciona una nacionalidad</div>
                        </div>
                        <div className="pt-3 bg-white">
                            {items.map((el,index)=>(
                                <NacionalitiesItemComponent
                                    key={index}
                                    code={el.code}
                                    name={el.name}
                                    onClick={()=>{
                                        person.nacionality={
                                            code:el.code,
                                            name:el.name,
                                        }
                                        props.onUpdatePerson(person);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>  
    );
}