import React, { useState} from 'react';
import '../css/bottom-sheet.css';
import Postals from './Postals';
import Loading from './Loading';
function LoginBottomSheetSendCode(props) {
    const [user] = useState(props.user);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [postal,setPostal] = useState({alpha2Code: "pe",name: "Peru",callingCodes: 51});
    const [isPostalsVisible,setPostalsVisible] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
  
    const sendCode = () => {
      const urlApi="https://apis.gestionafacil.site/paracas.online/v1.0/account/sendCode";
      const number=postal.callingCodes+phoneNumber;
      
      user.cellPhonePostal=postal.callingCodes;
      user.cellPhoneNumber=phoneNumber;
      
      setIsLoading(true);
      

      fetch(urlApi,{
          method:'POST',
          body: JSON.stringify({"cellphoneNumber":number})
      })
      .then(res=>res.json())
      .then((r) => {
          setIsLoading(false);
          props.onUpdateCode(r.response.code);
          props.onUpdateUser(user);
          props.onUpdateIsLoginBottomSheetVerifyCode(true);
          setOnClose();
      });
    }

    const setOnClose = () => {
      props.onClose();
    }

    const handlePhoneNumber = (e) => {
        const inputText = e.target.value;
        const numericValue= inputText.replace(/\D/g, '');
        setPhoneNumber(numericValue);
    }
  
    const handleClose = (e) => {
      if (e.target === e.currentTarget) {
        props.onClose();
      }
    };
    
    const showPostalComponent = () => {
      setPostalsVisible(true);
    };

    return (
     <>
      
      <div className="backdrop" onClick={handleClose}>
        <div className="bottom-sheet">
          <div className="d-flex justify-content-between">
            <div>&nbsp;</div>
            <p className="text-center fw-bold fs-6 col-10 text-uppercase p-2">Paso 1 - Ingrese su número de celular</p>
            <div className="p-2" onClick={props.onClose}><i className="fa-solid fa-x"></i></div>
          </div>
          <div className="d-flex flex-column">
            <p className="text-muted fs-6 text-start">Generemos un código de verificación y se lo enviaremos vía Whatsapp</p>
            <div className="d-flex justify-content-between border">
                <div className="col-4 d-flex align-items-center justify-content-center" onClick={showPostalComponent}>
                    <div className="d-flex justify-content-start"><span>+{postal.callingCodes}&nbsp;</span><i className="fa-solid fa-caret-down"></i></div>
                </div>
                <input type="text"  value={phoneNumber} onChange={handlePhoneNumber} autoFocus placeholder={"Número de celular"} className="w-100" />  
            </div>
            <div className="mt-3">
                <a className="btn btn-teal w-100" onClick={sendCode}>Siguiente</a>
            </div>
          </div>
        </div>
      </div>
        {isLoading && <Loading />}
        {isPostalsVisible && <Postals onClose={() => setPostalsVisible(false)}
          onUpdatePostal={(postalData) => {
            setPostal(postalData);
            setPostalsVisible(false);
          }}/>
        }
      </>
    );
  }
  
  export default LoginBottomSheetSendCode;