import React,{useState} from 'react';
import '../css/bottom-sheet.css';
import '../css/dots.css';
import { logIn } from '../redux/userSlice';
import { useDispatch } from 'react-redux';
import Loading2 from './Loading2';
import AlertComponent from './AlertComponent';
import PasswordTableComponent from './PasswordTableComponent';
export default function LoginBottonSheetPasswordTable(props){
    const dispatch = useDispatch();
    const [alertMessage,setAlertMessage] = useState("");
    const [showAlertMessage,setShowAlertMessage] = useState(false);
    const [user,setUser] = useState(props.user);
    const [isLoading2,setIsLoading2] = useState(false);
    const [isPassComplete,setIsPassComplete] = useState(false);
    const onLogin = () => {
        
        setIsLoading2(true);
        const url="https://apis.gestionafacil.site/paracas.online/v1.0/account/login";
        fetch(url,{
            method:'POST',
            body: JSON.stringify({
                cellPhoneNumber:user.cellPhoneNumber,
                cellPhonePostalId: user.cellPhonePostal.callingCodes,
                password: user.password,
            })
        })
        .then(res => res.json())
        .then(
            (responseData) => {
                setIsLoading2(false);
                const res=responseData.response;
                if(!res.success){
                    setAlertMessage(
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-center text-danger mb-2"><i className="fa-solid fa-circle-xmark fa-4x"></i></div>
                            <div className="text-center text-capitalize">{res.msg}</div>
                        </div>
                    );
                    setShowAlertMessage(true);
                    return;
                }
             
                dispatch(logIn({token:res.token}));
                props.onClose();
                            
            }
        );
    };
    const updatePassword=(value)=>{
        user.password=value;
        setUser(user);
    }

    const updateIsPassComplete = (value) =>{
        setIsPassComplete(value);
    }
    return (
        <>
            <div className="backdrop">
                <div className="bottom-sheet">
                    <div className="d-flex justify-content-between">
                        <div>&nbsp;</div>
                        <p className="text-center fw-bold fs-6 col-10 text-uppercase p-2">Ingresa tu clave</p>
                        <div className="p-2" onClick={()=>props.onClose()}><i className="fa-solid fa-x"></i></div>
                    </div>
                    <div className="mt-3">
                        <PasswordTableComponent onUpdatePassword={updatePassword} onUpdateIsPassComplete={updateIsPassComplete} />
                    </div>
                    <div className="mt-3">
                        <a className={`btn btn-teal w-100 ${!isPassComplete ? 'disabled' : ''}`} onClick={onLogin}>Iniciar sesión</a>
                    </div>
                </div>
            </div>
            {showAlertMessage && <AlertComponent message={alertMessage} onAccept={()=>{
                setShowAlertMessage(false);
             }
            } />}
            {isLoading2 && <Loading2 msg={"Iniciando sesión, espere por favor..."} />}
        </>
    );
}