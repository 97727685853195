import { createSlice } from '@reduxjs/toolkit';

const loadUserFromStorage = () => {
  try {
    const serializedUser = localStorage.getItem('user');
    return serializedUser ? JSON.parse(serializedUser) : { token: null };
  } catch (error) {
    console.error('Error loading user from storage:', error);
    return { token: null };
  }
};

const saveUserToStorage = (user) => {
  try {
    const serializedUser = JSON.stringify(user);
    localStorage.setItem('user', serializedUser);
  } catch (error) {
    console.error('Error saving user to storage:', error);
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: loadUserFromStorage(),
  reducers: {
    logIn: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      saveUserToStorage(state);
    },
    updateUser: (state, action) => {
      state.token = action.payload.token; // Actualizamos el token
      saveUserToStorage(state);
    },
    logOut: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      saveUserToStorage(state);
    },
  },
});

export const { logIn, logOut, updateUser } = userSlice.actions;

export default userSlice.reducer;