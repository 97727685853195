import React,{Component} from 'react';
import '../css/passenger.css';
export default class Passengers extends Component{
    constructor(props){
        super(props);
        this.state={
            adults: props.pax.adults,
            children: props.pax.children,
            babies: props.pax.babies,
        }
    }
    handleIncrement = (type) => {
        this.setState((prevState) => ({
            [type]: prevState[type] + 1,
        }));
    };

    handleDecrement = (type) => {
        if (this.state[type] > 0) {
            this.setState((prevState) => ({
                [type]: prevState[type] - 1,
            }));
        }
    };

    handleApply = () => {
        this.props.onUpdatePassenger(this.state);
    }
    closeModal = () => {
        this.props.onClose();
    }
    render(){
        return (
            <>
                <div className="passenger">
                    <div className="passenger-content bg-white">
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-start shadow-sm pt-2 pb-2 bg-white rounded">
                                <div className="p-2 d-flex align-items-center" onClick={this.closeModal}><i className="fa-solid fa-arrow-left fa-2x color-yellow"></i></div>
                                <div className="p-2 fw-bold d-flex align-items-center">Selecciona la cantidad de pasajeros</div>
                            </div>
                            <div className="p-3 d-flex justify-content-between">
                                <div className="col-6 d-flex flex-column p-0">
                                    <p className="fs-1 fw-bold mb-0 p-0">Adultos</p>
                                    <p className="mt-n4 p-0 fs-6"><small>Mayores de 18 años</small></p>
                                </div>
                                <div className="col-5 d-flex align-items-center">
                                    <div className="input-group">
                                        <button onClick={() => this.handleDecrement('adults')} className="btn btn-outline-secondary" type="button" >-</button>
                                        <input type="text" readOnly={true} className="form-control text-center border" value={this.state.adults} />
                                        <button onClick={() => this.handleIncrement('adults')} className="btn btn-outline-secondary" type="button" >+</button>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3 d-flex justify-content-between">
                                <div className="col-6 d-flex flex-column p-0">
                                    <p className="fs-1 fw-bold mb-0 p-0">Niños</p>
                                    <p className="mt-n4 p-0 fs-6"><small>Menores de 18 y Mayores de 3 años</small></p>
                                </div>
                                <div className="col-5 d-flex align-items-center">
                                    <div className="input-group">
                                        <button onClick={() => this.handleDecrement('children')} className="btn btn-outline-secondary" type="button" >-</button>
                                        <input type="text" readOnly={true} className="form-control text-center border" value={this.state.children} />
                                        <button onClick={() => this.handleIncrement('children')} className="btn btn-outline-secondary" type="button" >+</button>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3 d-flex justify-content-between">
                                <div className="col-6 d-flex flex-column p-0">
                                    <p className="fs-1 fw-bold mb-0 p-0">Infantes</p>
                                    <p className="mt-n4 p-0 fs-6"><small>Menores de 3 y mayores de 1 año</small></p>
                                </div>
                                <div className="col-5 d-flex align-items-center">
                                    <div className="input-group">
                                        <button onClick={() => this.handleDecrement('babies')} className="btn btn-outline-secondary" type="button" >-</button>
                                        <input type="text" readOnly={true} className="form-control text-center border" value={this.state.babies} />
                                        <button onClick={() => this.handleIncrement('babies')} className="btn btn-outline-secondary" type="button" >+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="fixed-bottom btn btn-teal btn-lg fs-1" onClick={this.handleApply}>Aplicar</button>
                    </div>
                </div>
            </>
        )
    }
}