import React,{useState,useEffect} from 'react';
import Postals from './Postals';
import DocumentTypeListComponent from './DocumentTypeListComponent';
import NacionalitiesListComponent from './NacionalitiesListComponent';
import Loading from './Loading';
import Loading2 from './Loading2';
import AlertComponent from './AlertComponent';
export default function CustomerStepComponent(props){
    const [alertComponentOpen,setAlertComponentOpen] = useState(false);
    const [alertMessage,setAlertMessage] = useState("");
    const [customer,setCustomer] = useState(props.customer);
    const [msgLoading,setMsgLoading] = useState("");
    const [ispostalComponentOpen,setIsPostalsComponentsOpen] = useState(false);
    const [documents,setDocuments] = useState([]);
    const [nacionalities,setNacionalities] = useState([]);
    const [isDocumentTypeListVisible,setIsDocumentTypeListVisible] = useState(false);
    const [isNacionalitiesListVisible,setIsNacionalitiesListVisible] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoading2,setIsLoading2] = useState(false);
    
    useEffect(() => {
            setIsLoading(true);
            const url="https://apis.gestionafacil.site/paracas.online/v1.0/persons/especialData";
            fetch(url)
            .then(res => res.json())
            .then(
                (responseData) => {

                    const res=responseData.response.data;
                    const documentType=res.documents.length===0 ? null : res.documents[0];
                    customer.documentType=documentType;
                    
                    const filteredNacionality = res.nacionalities.filter(n => n.code === 'pe');
                    const nacionality = filteredNacionality.length === 0 ? null : filteredNacionality[0];
                    customer.nacionality=nacionality

                    setCustomer(customer);
                    props.onUpdateCustomer(customer);
                    
                    setDocuments(res.documents);
                    setNacionalities(res.nacionalities);
                    setIsLoading(false);
                    
                }
            )
            .catch(error => {

                console.error("Error de conexión:", error);
                setAlertComponentOpen(true);
                setAlertMessage(error);
                // Realiza acciones adicionales, como mostrar un mensaje al usuario
            });
    },[customer]);

    return (
        <>
            <div className="m-2">
                <br />
                <label className="p-2 fw-bold">Datos personales</label>
                <div className="px-1 pt-2 pb-2 bg-white border rounded">
                    <div className="d-flex flex-column m-1">
                        <div className="form-group">
                            <div className="d-flex justify-content-center mb-1">
                                <div className="d-flex justify-content-between border">
                                    <div className="col-4 d-flex align-items-center justify-content-center">
                                        <div className="d-flex justify-content-start">
                                            <span className="fs-6 px-4 pt-1 pb-1 text-uppercase" onClick={
                                                ()=>{
                                                    setIsDocumentTypeListVisible(true);
                                                    props.onIsFullModalOpen(true);
                                                }
                                            }>{customer.documentType===null ? "" : customer.documentType.denomination_short}&nbsp;</span>
                                            <div className="d-flex align-items-center"><i className="fa-solid fa-caret-down"></i>&nbsp;&nbsp;</div>
                                        </div>
                                    </div>
                                    <input type="text" autoFocus onChange={(e) =>{
                                            customer.documentNumber=e.target.value;
                                        }
                                    } defaultValue={customer.documentNumber} className="w-100" />
                                </div> 
                                <div className="d-flex align-items-center justify-content-center m-1">
                                    <a className="btn btn-primary pt-2 pb-2" onClick={()=>{
                                        setMsgLoading("Buscando informacion, espere por favor...");
                                        setIsLoading2(true);
                                        fetch("https://apis.gestionafacil.site/paracas.online/v1.0/persons/search",{
                                            method:'POST',
                                            body: JSON.stringify({
                                                documentType:customer.documentType.denomination_short,
                                                documentNumber:customer.documentNumber
                                            })
                                        })
                                        .then(response => response.json())
                                        .then(responseData => {
                                            setIsLoading2(false);
                                            const result=responseData.response;
                                            //alert(JSON.stringify(result))
                                            if(!result.success){
                                                alert(result.msg);
                                                return;
                                            }
                                            
                                            const filteredNacionality = nacionalities.filter(n => n.code === (result.data===null ? "es" : result.data.nacionality_code));
                                            const nacionality = filteredNacionality.length === 0 ? null : filteredNacionality[0];
                                            
                                            customer.id=result.data===null ? 0 : result.data.id;
                                            customer.names=result.data===null ? "" : result.data.names;
                                            customer.surnames=result.data===null ? "" : result.data.surnames;
                                            customer.nacionality=nacionality;
                                            customer.email=result.data===null ? "" : result.data.email;
                                            customer.dateBirth=result.data===null ? "0000-00-00" : result.data.dateBirth;
                                            customer.cellphonePostalId=result.data===null ? "51" : result.data.cellphonePostalId;
                                            customer.cellphoneNumber=result.data===null ? "" : result.data.cellphoneNumber; 
                                            setCustomer(customer);
                                            props.onUpdateCustomer(customer);
                                        });
                                    }}>Buscar</a>
                                </div> 
                            </div>
                        </div>
                        <div className="form-group mt-1">
                            <input type="text" onChange={(e) =>{customer.names=e.target.value;}} defaultValue={customer.names} placeholder={('Nombres')} className="fs-6 form-control form-control-lg border" />
                        </div>
                        <div className="form-group mt-1">
                            <input type="text" onChange={(e) =>{customer.surnames=e.target.value;}} defaultValue={customer.surnames} placeholder={('Apellidos')} className="fs-6 form-control form-control-lg border" />
                        </div>    
                        <div className="border mt-1">
                            <div className="">
                                <div className="col-12 d-flex justify-content-between px-2 pt-2 pb-2" onClick={()=>{
                                        setIsNacionalitiesListVisible(true);
                                        props.onIsFullModalOpen(true);
                                    }}>
                                    <div className="text-uppercase fs-6">&nbsp;&nbsp;{customer.nacionality===null ? "" : customer.nacionality.name}&nbsp;</div>
                                    <div><i className="fa-solid fa-caret-down"></i></div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-1">
                            <label className="text-muted px-2 mt-2">Fecha de nacimiento:</label>
                            <input type="date" onChange={(e)=>{customer.dateBirth=e.target.value}} defaultValue={customer.dateBirth} placeholder={('YYYY-MM-DD')} className="fs-6 form-control form-control-lg border" />
                        </div>
                    </div>
                </div>
                <br />
                <label className="p-2 fw-bold">Detalle de contacto</label>
                <div className="p-2 bg-white">
                    <div className="d-flex flex-column m-1">
                        <div className="form-group">
                            <small className="fs-6 w-100 text-muted">Una copia de su eBooking sera enviado al siguiente mail y movil (Vía whatsapp).</small>
                            <label className="w-100 mt-2 text-muted">Email:</label>
                            <input type="text" onChange={(e) =>{customer.email=e.target.value;}} defaultValue={customer.email} placeholder={('Correo electrónico')} className="fs-6 form-control form-control-lg border" />
                        </div>
                        <div className="form-group mt-1">
                            <label className="w-100 mt-2 text-muted">Celular:</label>
                            <div className="d-flex justify-content-between border">
                                <div className="col-4 d-flex align-items-center justify-content-center" onClick={()=>{
                                    setIsPostalsComponentsOpen(true);
                                    props.onIsFullModalOpen(true);
                                }}>
                                    <div className="d-flex justify-content-start">
                                        <span className="fs-6 px-4 pt-1 pb-1 text-uppercase">+{customer.cellphonePostalId}&nbsp;</span>
                                        <div className="d-flex align-items-center"><i className="fa-solid fa-caret-down"></i>&nbsp;&nbsp;</div>
                                    </div>
                                </div>
                                <input type="text" onChange={(e)=>{customer.cellphoneNumber=e.target.value}} defaultValue={customer.cellphoneNumber} placeholder={"Número de celular"} className="w-100" />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            {isLoading2 && <Loading2 msg={msgLoading} />}
            {ispostalComponentOpen && (<Postals onClose={()=>{
                                setIsPostalsComponentsOpen(false);
                                props.onIsFullModalOpen(false);
                            }
                        } 
                        onUpdatePostal={
                            (postalData)=>{
                                customer.cellphonePostalId=postalData.callingCodes;
                                setCustomer(customer);
                                setIsPostalsComponentsOpen(false);
                                props.onIsFullModalOpen(false);
                            }
                        } 
                    />
                )
            }
            {isDocumentTypeListVisible && <DocumentTypeListComponent data={documents} person={customer} onUpdatePerson={(value)=>{
                setCustomer(value);
                props.onUpdateCustomer(customer);
                setIsDocumentTypeListVisible(false);
                props.onIsFullModalOpen(false);
            }} />}
            {isNacionalitiesListVisible && <NacionalitiesListComponent data={nacionalities} person={customer} onUpdatePerson={(value)=>{
                setCustomer(value);
                props.onUpdateCustomer(customer);
                setIsNacionalitiesListVisible(false);
                props.onIsFullModalOpen(false);
            }} />}
            {alertComponentOpen && <AlertComponent message={alertMessage} onAccept={()=>{alert("No hay")}} />}
        </>
        
    );
}