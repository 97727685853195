import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { addToCart } from '../redux/cartSlice';

import moment from 'moment';
import Loading from './Loading';
import FloatingCart from './FloatingCart';
import CartShoppingComponent from './CartShoppingComponent';
import BallestasSearchResultToolbar from './BallestasSearchResultToolbar';
import LoginBottomSheetSignIn from './LoginBottomSheetSignIn';
import LoginBottomSheetSendCode from './LoginBottomSheetSendCode';
import LoginBottomSheetVerifyCode from './LoginBottomSheetVerifyCode';
import LoginBottomSheetPersonalInformations from './LoginBottomSheetPersonalInformations';
import LoginBottomSheetPassword from './LoginBottonSheetPassword';
import LoginBottonSheetPasswordTable from './LoginBottomSheetPasswordTable';
import ConfimComponent from './ConfirmComponent';
import '../css/added-layout.css';
function BallestaItemDeparture(props){
    const [isCartShoppingComponentOpen,setIsCartShoppingComponentOpen] = useState(false);
    const [showConfirmComponent,setShowConfirmComponent] =useState(false);
    const [isAddedToCart, setIsAddedToCart] = useState(true);
    const isAuthenticated = useSelector((state) =>state.user.isAuthenticated);
    const dispatch=useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    const [cartCount, setCartCount] = useState(cartItems ? cartItems.length : 0);

    useEffect(() => {
      // Este efecto se ejecutará cuando isAddedToCart cambie
      const isItemInCart = cartItems.some((item) => item.id === props.data.id);
      setIsAddedToCart(isItemInCart);
    }, [cartItems, props.data.id]); // Dependencias: cartItems y props.data.id

    
    const handleDeparture = () =>{
      if(isAuthenticated){
        setShowConfirmComponent(true);
      }else{
        props.onOpenLoginBottomSheetSignIn(true);
      }
    }

    const addToCartShopping = () =>{
      const orderDetail={
        id:props.data.id,
        model:props.data.object,
        dbReference:"paracas_online",
        departureDate:moment(props.date+" "+props.data.time).format('YYYY-MM-DD HH:mm:ss'),
        currency:props.data.currency,
        totalToPay:parseFloat(parseFloat(props.adults*props.data.unit_cost.adults)+parseFloat(props.children*props.data.unit_cost.children)+parseFloat(props.babies*props.data.unit_cost.babies)),
        pax:{
          adults:props.adults,
          children:props.children,
          babies:props.babies,
        },
        unitPrice:{
          adults:props.data.unit_cost.adults,
          children:props.data.unit_cost.children,
          babies:props.data.unit_cost.babies
        },
        business:props.data.business,
        boarding:props.data.boarding,
        destination:props.data.destination,
      };
      
      dispatch(addToCart(orderDetail));
      setCartCount(cartItems ? cartItems.length + 1 : 1); // Incrementar el contador
    }
    return (
      <>
        <div className="bg-white position-relative">
            {isAddedToCart && isAuthenticated && (
              <div className='overlay'>
                  <p className="added-text">AGREGADO</p>
              </div>
            )}
            <div className="bg-white">
              <div className="d-flex justify-content-between p-3 mx-1 mb-1 mt-1" onClick={handleDeparture}>
                  <div className="col-8">
                      <h1 className="fs-5 fw-bold text-uppercase text-teal m-0">{props.data.business.name}</h1>
                      <p className="text-teal m-0 pt-2"><i className="fa-solid fa-clock"></i>&nbsp;{moment(props.date+' '+props.data.time).format('hh:mm a')}&nbsp;|&nbsp;02 horas aprox.</p>
                      <p className="text-muted m-0 pt-1 text-capitalize"><small className="text-muted"><i className="fa-solid fa-building"></i>&nbsp;{props.data.boarding.denomination_long} - <i className="fa-solid fa-location-dot"></i>&nbsp;{props.data.destination.denomination_long}</small></p>
                      <p className="mt-2">
                        <span className="badge text-bg-azure mx-1 text-uppercase"><i className="fa-solid fa-umbrella"></i>&nbsp;embarcaciones con sombra</span>
                        <span className="badge text-bg-azure mx-1 text-uppercase"><i className="fa-solid fa-bullhorn"></i>&nbsp;Guia bilingue</span>
                        <span className="badge text-bg-azure mx-1 text-uppercase"><i className="fa-solid fa-wifi"></i>&nbsp;Wifi</span>
                        <span className="badge text-bg-azure mx-1 text-uppercase"><i className="fa-solid fa-life-ring"></i>&nbsp;Chalecos salvavidas</span>
                      </p>
                  </div>
                  <div className="d-flex align-items-center">
                      <div className="">
                          <p className="text-center m-0"><span className="badge text-bg-warning pt-3 pb-3">{parseFloat(props.data.business.rating).toFixed(2)}</span></p>
                          <p className="text-center mb-2 text-capitalize">{props.data.business.rating_str}</p>
                          <p className="text-center mt-1 mb-0 text-muted"><small>Total a pagar</small></p>
                          <p className="text-center text-teal fw-bold m-0 text-uppercase">{props.data.currency} {parseFloat(parseFloat(props.adults*props.data.unit_cost.adults)+parseFloat(props.children*props.data.unit_cost.children)+parseFloat(props.babies*props.data.unit_cost.babies)).toFixed(2)}</p>
                          <p className="text-center mt-1 mb-0 text-muted"><small>Pasajeros</small></p>
                          <p className="text-center text-teal m-0"><i className="fa-solid fa-people-group"></i><span className="fw-bold text-muted">&nbsp;{parseInt(props.adults)+parseInt(props.children)}{props.babies>0 ? '+'+props.babies : ''}</span></p>
                      </div>
                  </div>  
              </div>
          </div>
        </div>
        
        {cartItems.length>0 && isAuthenticated && (<FloatingCart cartCount={cartCount} onUpdateIsCartShoppingComponentOpen={(value)=>{setIsCartShoppingComponentOpen(value);}} />)}
        {isCartShoppingComponentOpen && <CartShoppingComponent  onClose={()=>{setIsCartShoppingComponentOpen(false);}} />}  
        {showConfirmComponent && <ConfimComponent message={"¿Desea agregar al carrito?"} onCancel={()=>{setShowConfirmComponent(false)}} onAccept={()=>{
          addToCartShopping();
          setShowConfirmComponent(false)
        }}/>}
      </>
    );
}
export default function BallestasSearchResult() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const date = searchParams.get('departure') 
  const paxAdults = searchParams.get('adults');
  const paxChildren = searchParams.get('children');
  const paxBabies = searchParams.get('babies');
  const destination = searchParams.get('destination') || 'all'; // Provide a default destination
  const boarding = searchParams.get('boarding') || 'all'; // Provide a default boarding location
  const lang = searchParams.get('lang') || 'es'; // Provide a default language

  const url = `https://apis.gestionafacil.site/paracas.online/v1.0/departures?date=${date}&passenger_number=${paxAdults+paxChildren+paxChildren}&destination=${destination}&boarding=${boarding}&lang=${lang}`;

  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [isLoginBottomSheetPasswordTable,setIsLoginBottomSheetPasswordTable] = useState(false);
  const [isLoginBottomSheetSignIn,setIsLoginBottomSheetSignIn] = useState(false);
  const [isLoginBottomSheetSendCodeOpen, setIsLoginBottomSheetSendCodeOpen] = useState(false);
  const [isLoginBottomSheetPassword,setIsLoginBottomSheetPassword] = useState(false);
  const [isLoginBottomSheetVerifyCodeOpen, setIsLoginBottomSheetVerifyCodeOpen] = useState(false);
  const [isLoginBottomSheetPersonalInformations,setIsLoginBottomSheetPersonalInformations] = useState(false);
  
  const [code,setCode] = useState(null);
  const [user,setUser] = useState({});

  const updateCode = (code) =>{
    setCode(code);
  }
  const updateUser = (user) =>{
      setUser(user);
  }
  const updateIsLoaginBottomSheetPasswordTable = (value) =>{
    setIsLoginBottomSheetPasswordTable(value);
  }
  const updateisLoginBottomSheetSignIn = (value) =>{
    setIsLoginBottomSheetSignIn(value);
  }
  const updateIsLoginBottomSheetSendCode = (value) =>{
    setIsLoginBottomSheetSendCodeOpen(value);
  }
  const updateIsLoginBottomSheetVerifyCodePen = (value) =>{
    setIsLoginBottomSheetVerifyCodeOpen(value);
  }
  const updateIsLoginBottomSheetPassword = (value) => {
    setIsLoginBottomSheetPassword(value);
  }
  const updateIsLoginBottomSheetPersonalInformations = (value) =>{
    setIsLoginBottomSheetPersonalInformations(value);
  }


  useEffect(() => {
    // Fetch data from the API
    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setSearchResults(json.response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <>
      {loading && <Loading />}
      <BallestasSearchResultToolbar onOpenLoginBottomSheetSignIn={updateisLoginBottomSheetSignIn} boarding={boarding} destination={destination} adults={paxAdults} children={paxChildren} babies={paxBabies}  date={date} />
      {searchResults.map((result, index) => (
        <div key={index}>
          <BallestaItemDeparture onOpenLoginBottomSheetSignIn={updateisLoginBottomSheetSignIn} adults={paxAdults} children={paxChildren} babies={paxBabies} date={date} data={result} />
        </div>
      ))}
      {isLoginBottomSheetSignIn && <LoginBottomSheetSignIn onUpdateUser={(value)=>{setUser(value);}} onUpdateIsLoginBottomSheetPasswordTable={updateIsLoaginBottomSheetPasswordTable} onUpdateIsLoginBottomSheetSendCode={updateIsLoginBottomSheetSendCode} onClose={()=>setIsLoginBottomSheetSignIn(false)}/>}
      {isLoginBottomSheetSendCodeOpen && <LoginBottomSheetSendCode user={user} onUpdateUser={updateUser} onUpdateCode={updateCode} onClose={() => setIsLoginBottomSheetSendCodeOpen(false)}  onUpdateIsLoginBottomSheetVerifyCode={updateIsLoginBottomSheetVerifyCodePen} />}
      {isLoginBottomSheetVerifyCodeOpen && <LoginBottomSheetVerifyCode  user={user} onUpdateUser={updateUser} code={code} onClose={() => setIsLoginBottomSheetVerifyCodeOpen(false)} onUpdateIsLoginBottomSheetPassword={updateIsLoginBottomSheetPassword} />}
      {isLoginBottomSheetPassword && <LoginBottomSheetPassword user={user} onUpdateUser={updateUser} onClose={() => setIsLoginBottomSheetPassword(false)} onUpdateIsLoginBottomSheetPersonalInformations={updateIsLoginBottomSheetPersonalInformations}/>}
      {isLoginBottomSheetPersonalInformations && <LoginBottomSheetPersonalInformations  user={user} onUpdateUser={updateUser} onClose={() => setIsLoginBottomSheetPersonalInformations(false)} />}
      {isLoginBottomSheetPasswordTable && <LoginBottonSheetPasswordTable user={user} onClose={()=>{setIsLoginBottomSheetPasswordTable(false);}} /> }
      
    </>
  );
}