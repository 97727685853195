import React,{useState} from 'react';
import '../css/bottom-sheet.css';
import '../css/dots.css';
import PasswordTableComponent from './PasswordTableComponent';
export default function LoginBottonSheetPassword(props){
    const [user,setUser] = useState(props.user);
    const [isPassComplete,setIsPassComplete] = useState(false);
    const onNext = () => {
        props.onUpdateUser(user);
        props.onUpdateIsLoginBottomSheetPersonalInformations(true);
        props.onClose();
    };
    const updatePassword=(value)=>{
        user.password=value;
        setUser(user);
    }

    const updateIsPassComplete = (value) =>{
        setIsPassComplete(value);
    }
    return (
        <>
            <div className="backdrop">
                <div className="bottom-sheet">
                    <div className="d-flex justify-content-between">
                        <div>&nbsp;</div>
                        <p className="text-center fw-bold fs-6 col-10 text-uppercase p-2">Paso 3 - Elije una contraseña</p>
                        <div className="p-2" onClick={()=>props.onClose()}><i className="fa-solid fa-x"></i></div>
                    </div>
                    <div className="mt-3">
                        <PasswordTableComponent onUpdatePassword={updatePassword} onUpdateIsPassComplete={updateIsPassComplete} />
                    </div>
                    <div className="mt-3">
                        <a className={`btn btn-teal w-100 ${!isPassComplete ? 'disabled' : ''}`} onClick={onNext}>Siguiente</a>
                    </div>
                </div>
            </div>
        </>
    );
}