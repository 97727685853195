import React,{useState} from 'react';
import '../css/confirm.css';
export default function ConfirmComponent(props){
    return (
        <>
            <div className="confirm">
                <div className="confirm-content">
                    <p>{props.message}</p>
                    <div className="button-container">
                        <button className="btn btn-teal" onClick={()=>{props.onAccept()}}>Si</button>
                        <button className="btn btn-light" onClick={()=>{props.onCancel()}}>No</button>
                    </div>
                </div>
            </div>
        </>
    );
}