import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {logOut,updateUser} from '../redux/userSlice';
import { removeFromCart,clearCart } from '../redux/cartSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import '../css/step.css';

import AlertComponent from './AlertComponent';
import CustomerStepComponent from './CustomerStepComponent';
import MethodOfPayStepComponent from './MethodOfPayStepComponent';
import MethodOfPayContent from './MethodOfPayContent';
import ReviewStepComponent from './ReviewStepComponent';
import Loading from './Loading';
import Loading2 from './Loading2';


export default function Checkout() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const cartItems = useSelector((state) => state.cart.items);
    //const totalAmount = cartItems.reduce((total, item) => total + parseFloat(item.totalToPay), 0).toFixed(2);
    
    const token = useSelector((state) => state.user.token);
    
    const [methodOfPaySelected,setMethodOfPaySelected] = useState(1);
    const [showMethodOfPayContent,setShowMethodOfPayContent] = useState(false);
    const [showAlert,setShowAlert] = useState(false); 
    const [alertMessage,setAlertMessage] = useState("");
    const [alertAcceptButton,setAlertAcceptButton] = useState();
    const [isFullModalOpen,setIsFullModalOpen] = useState(false);
    const [currentStep,setCurrentStep] = useState(1);
    const [showLoading,setShowLoading] = useState(false);
    const [showLoading2,setShowLoading2] = useState(false);
    const [msgLoading,setMsgLoading] = useState();
    const [saleDetails,setSaleDetails] = useState();
    const [customer,setCustomer] = useState({
        id:0,
        cellphonePostalId:'51',
        cellphoneNumber:'',
        documentType:null,
        documentNumber:"",
        names:"",
        surnames:"",
        nacionality:null,
        email:"",
        dateBirth:"",
    });
    const steps=[
            {
                code:1,name:{es:"Responsable"},
                btn:{label:"Continuar reservando",action: ()=>{
                    const url=`https://apis.gestionafacil.site/paracas.online/v1.0/sales/update/${id}`;
                  
                    fetch(url,{
                        method: 'POST',
                        body: JSON.stringify({
                            customer:{
                                id:customer.id,
                                cellphonePostalId:customer.cellphonePostalId,
                                cellphoneNumber:customer.cellphoneNumber,
                                documentTypeId:customer.documentType.id,
                                documentNumber:customer.documentNumber,
                                names:customer.names,
                                surnames:customer.surnames,
                                nacionalityCode:customer.nacionality.code,
                                email:customer.email,
                                dateBirth:customer.dateBirth
                            },
                        stepCurrent:currentStep,
                        token:token}),
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(responseData => {
                        const r=responseData.response;
                        if (r.success) {
                            const tokenUpdated=r.token_updated;
                            dispatch(updateUser({token:tokenUpdated}));
                            setCurrentStep(r.data.stepCurrent);
                        
                        } else {
                            alert('Error al insertar en la base de datos');
                        }
                    })
                    .catch(error => {
                        console.error('Error en la solicitud:', error);
                        alert('Error en la solicitud al servidor');
                    });
                }}
            },
            {
                code:2,name:{es:"Medio de pago"},
                btn:{label:"Ir a pagar",action: ()=>{
                    setIsFullModalOpen(true);
                    setShowMethodOfPayContent(true);
                }}
            },
            {
                code:3,name:{es:"Resumen"},
                btn:{label:`Finalizar`,action:()=>{
                    const url=`https://apis.gestionafacil.site/paracas.online/v1.0/sales/update/${id}`;
                    setMsgLoading("Guardando registro...");
                    setShowLoading2(true);
                    fetch(url,{
                        method: 'POST',
                        body: JSON.stringify({
                            stepCurrent:currentStep,
                            token:token}
                        ),
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(responseData => {
                        const r=responseData.response;
                        if (r.success) {
                            
                            const tokenUpdated=r.token_updated;
                            dispatch(updateUser({token:tokenUpdated}));
                            if(r.data.stepCurrent>3){
                                setShowAlert(true);
                                setAlertMessage(
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-center text-success mb-2"><i className="fa-solid fa-circle-check fa-4x"></i></div>
                                        <div className="text-center">Registro guardado con exito</div>
                                    </div>
                                );
                                setAlertAcceptButton(() => {return ()=>{
                                    
                                    setCurrentStep(r.data.stepCurrent);
                                    window.location.href = window.location.origin;
                                }});
                            }

                        } else {
                            alert('Error al insertar en la base de datos');
                        }
                    })
                    .catch(error => {
                        console.error('Error en la solicitud:', error);
                        alert('Error en la solicitud al servidor');
                    });
                }}
            },
            {
                code:4, name: {"es":"finalizado"},
                btn:{label:'Finalizado',action:()=>{
                   
                }}
            }
    ];
    const filteredStep = steps.filter(s => s.code === currentStep);

  useEffect(()=>{
        setShowLoading(true);
        setShowLoading2(true);
        const url=`https://apis.gestionafacil.site/paracas.online/v1.0/sales/${id}?token=${token}`;
        fetch(url)
        .then(response=>response.json())
        .then((responseData)=>{
            setShowLoading(false);
            const r=responseData.response;
            
            if(!r.success){

                dispatch(logOut());
                setShowAlert(true);
                setAlertMessage("Su token ha expirado");
                setAlertAcceptButton(()=>{
                    window.location.href = window.location.origin;
                });

            }else{
                
                const tokenUpdated=r.token_updated;
                dispatch(updateUser({token:tokenUpdated}));
                setCurrentStep(r.data.stepCurrent);
                setSaleDetails(r.data.details);
                setShowLoading2(false);
                if(r.data.stepCurrent>3){
                   
                    setShowAlert(true);
                    setAlertMessage(
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-center text-danger mb-2"><i className="fa-solid fa-circle-xmark fa-4x"></i></div>
                            <div className="text-center">ID orden expirado</div>
                        </div>
                    );
                    setAlertAcceptButton(() => {return ()=>{
                        
                        setCurrentStep(r.data.stepCurrent);
                        window.location.href = window.location.origin;
                    }});
                }
            }
        })
        .catch(error => {

            setShowAlert(true);
            setAlertMessage(error);
            setAlertAcceptButton(()=>{window.location.reload()});
            
        });
  },[]);

  const totalAmount = () => {
        if (Array.isArray(saleDetails) && saleDetails.length > 0) {
            return saleDetails.reduce((total, detalle) => total + parseFloat(detalle.totalToPay), 0).toFixed(2);
        } else {
            return "0.00"; 
        }
  };
  return (
    <>
       
        <div className={`bg-white pb-4 ${!isFullModalOpen ? 'sticky-top' : ''}`}>
            <div className="d-flex justify-content-between mx-2 pt-2">
                <div className="d-flex align-items-center text-white">&nbsp;</div>
                <div className="d-flex align-items-center"><span className="fs-1 text-teal text-center fw-bold font-quicksand">Paracas<span className="text-darkorange">.online</span></span></div>
                <div className="d-flex align-items-center">&nbsp;
                </div>
            </div>
            <div className="step-container">
                {steps.map((el)=>(
                        el.code<4 && (
                            <div key={el.code} className={`${currentStep >= el.code ? 'step active' : 'step'} `}>
                                <span>{currentStep>el.code ? <i className="fa-solid fa-check"></i> : el.code}</span>
                                <p>{el.name.es}</p>
                            </div>
                        )
                ))}
            </div>
        </div>

        {currentStep===1 && (            
        <CustomerStepComponent customer={customer} onUpdateCustomer={(value)=>{setCustomer(value);}} onIsFullModalOpen={(value)=>{
            setIsFullModalOpen(value);
        }}/>)}

        {currentStep==2 && (
            <MethodOfPayStepComponent  onMethodOfPaySelected={(value)=>{setMethodOfPaySelected(value)}} />
        )}

        {currentStep==3 && (
            <ReviewStepComponent/>
        )}

        
        <br />
        <br />
        <br />
        <br />
        <br />    
        
        <div className={`bg-white pb-1 fixed-bottom`}>
            <div className="border-top border-2 border-dark">
                <div className="d-flex justify-content-between m-4">
                    <div className={currentStep==3 ? `d-none` : ``}>
                        <p className="p-0 m-0">PEN&nbsp;<span className="fs-1 fw-bold">{totalAmount()}</span></p>
                        <small className="mt-n2">Total a pagar</small>
                    </div>
                    <div className={`d-flex align-items-center ${currentStep==3 ? "col-12" : "col-6"}`}>
                        <button className="btn btn-warning w-100" onClick={filteredStep[0].btn.action}>{filteredStep[0].btn.label}</button>
                    </div>
                </div>
            </div>
        </div>

        {showLoading && (<Loading />)}
        {showLoading2 && (<Loading2  msg={msgLoading}/>)}
        {showAlert && (<AlertComponent onAccept={alertAcceptButton} message={alertMessage} />)}
        {showMethodOfPayContent && <MethodOfPayContent currentStep={currentStep} onUpdateCurrentStep={(value)=>{setCurrentStep(value)}} methodOfPaySelected={methodOfPaySelected} totalAmount={totalAmount()} onClose={()=>{setShowMethodOfPayContent(false)}} onIsFullModalOpen={(value)=>{
            setIsFullModalOpen(value);
        }}/>}
    </>
  );
}