import React,{useState} from 'react';
import '../css/full-modal.css';
function DocumentTypeItemComponent(props){
    
    return (
        <>
            <div>
                <div onClick={props.onClick} className="m-2 pt-1 px-2 pb-1 text-capitalize text-muted fs-6 d-flex align-items-center" ><i className="fw-bold text-black fa-solid fa-address-card fa-2x"></i>&nbsp;&nbsp;<small>{props.denomination_short} - {props.denomination_long}</small></div>
                <hr/>
            </div>
        </>
    );
}
export default function DocumentTypeListComponent(props){
    const [person,setPerson] = useState(props.person);
    const [items] = useState(props.data);
    return (
        <>
            <div className="full-modal">
                <div className="full-modal-content">
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-start shadow-sm pt-2 pb-2 bg-body-tertiary rounded bg-white">
                            <div className="p-2 d-flex align-items-center"><i className="fa-solid fa-arrow-left fa-2x color-yellow"></i></div>
                            <div className="p-2 fw-bold d-flex align-items-center">Selecciona un documento</div>
                        </div>
                        <div className="pt-3 bg-white">
                            {items.map((el)=>(
                                <DocumentTypeItemComponent
                                    key={el.id}
                                    denomination_short={el.denomination_short}
                                    denomination_long={el.denomination_long}
                                    onClick={()=>{
                                        person.documentType={
                                            id:el.id,
                                            denomination_short:el.denomination_short,
                                            denomination_long:el.denomination_long,
                                            sunat_id:el.sunat_id
                                        }
                                        props.onUpdatePerson(person);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>  
    );
}