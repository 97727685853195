import React,{useState,useEffect} from 'react';
import '../css/full-modal.css';
import { useSelector, useDispatch } from 'react-redux';
import {logOut,updateUser} from '../redux/userSlice';
import BadgeComponent from './BadgeComponent';
import ManageServicesComponent from './ManageServicesComponent';
import ManageWalletComponent from './ManageWalletComponent';

function Item(props){
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    return(
        <>
            <div>
                <div onClick={props.action} className="m-2 pt-1 pb-1 px-2 text-capitalize text-muted fs-6 d-flex align-items-center" >
                    <i className={`fw-bold text-muted fa-solid ${props.icon}`}></i>&nbsp;
                    <small>{props.name}</small>&nbsp;
                    {props.id===2 && isAuthenticated ? <BadgeComponent /> : ''}
                    {props.id===3 && isAuthenticated ? <span className="badge text-bg-dark">{props.user.balanceCurrency + ' ' + parseFloat(props.user.balanceAmount).toFixed(2)}</span> : ''}
                </div>
            </div>
        </>
    )
}
export default function MyAccountComponent(props){
    const [user, setUser] = useState({});
    const [showManageServices,setShowManageService] = useState(false);
    const [showManageWallet,setShowManageWallet] = useState(false);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();    
    
    useEffect(()=>{
        if(isAuthenticated){
            const url = `https://apis.gestionafacil.site/paracas.online/v1.0/account?token=${token}`;
            fetch(url)
            .then(response=>response.json())
            .then((responseData)=>{
                
                const r=responseData.response;
                if(!r.success){
                    dispatch(logOut());
                    window.location.reload();
                }else{
                    const tokenUpdated=r.token_updated;
                    setUser(r.user);
                    dispatch(updateUser({token:tokenUpdated}));
                }
            });
        }
    },[user]);

    const logout = () => {
        dispatch(logOut());
        props.onClose();
    }

    const settings = () =>{
        alert("ajustes");
    }

    const cancelService = () =>{
        alert("cancelar servicio");
    }
    const bookOfComplaints = () =>{
        alert("libro de reclamaciones");
    }

    const aboutUs = () => {
        alert("acerca de nosotros");
    }
    const help = () => {
        alert("ayuda");
    }

    const referAndEarn = () =>{
        alert("Referir y ganar");
    }

    const offers = () =>{
        alert("ofertas");
    }

    const wallet = () =>{
        setShowManageWallet(true);
    }
    
    const myService = () =>{
        setShowManageService(true);
    }

    const myProfile = () =>{
        alert("mi perfil");
    }

    const items=[
        {id:1,icon:"fa-user",name:{es:"mi perfil",en:"my profile"},action:myProfile},
        {id:2,icon:"fa-calendar",name:{es:"mis servicios",en:"my services"},action:myService},
        {id:3,icon:"fa-wallet",name:{es:"monedero",en:"wallet"},action:wallet},
        {id:4,icon:"fa-star",name:{es:"ofertas",en:"offers"},action:offers},
        {id:5,icon:"fa-bullhorn",name:{es:"referir y ganar",en:"refer & earn"}, action:referAndEarn},
        {id:6,icon:"fa-circle-question",name:{es:"ayuda",en:"help"}, action:help},
        {id:7,icon:"fa-building",name:{es:"sobre nosotros",en:"about us"},action:aboutUs},
        {id:8,icon:"fa-phone",name:{es:"libro de reclamaciones",en:"book of complaints"},action:bookOfComplaints},
        {id:9,icon:"fa-ban",name:{es:"cancelar servicio",en:"cancel service"}, action:cancelService},
        {id:10,icon:"fa-gear",name:{es:"ajustes",en:"settings"},action:settings},
        {id:11,icon:"fa-right-from-bracket",name:{es:"cerrar sesión",en:"logout"},action:logout},
    ];
    
    return (
        <>
            <div className="full-modal">
                <div className="full-modal-content">
                    <div className="bg-teal text-white">
                        <div className="d-flex justify-content-between">
                            <div className="p-4 d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img src={user.img} className="img-login-lg rounded-circle" />
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="p-2">
                                        <p className="mt-4 fs-3 mb-0 fw-bold">¡Hola, {user.names}!</p>
                                        <p className="mt-0">Bienvenido</p>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3 pb-4" onClick={props.onClose}><i className="fa-solid fa-x fa-2x"></i></div>
                        </div>
                      
                    </div>
                    {items.map((el)=>(
                        <Item
                            key={el.id}
                            name={el.name.es}
                            id={el.id}
                            icon={el.icon}
                            user={user}
                            action={el.action}
                        />
                    ))}
                </div>
            </div>
            {
                showManageServices && <ManageServicesComponent onClose={()=>{setShowManageService(false)}} />
            }
            {
                showManageWallet && <ManageWalletComponent onClose={()=>{setShowManageWallet(false)}} />
            }
        </>
    )
}