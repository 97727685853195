import React,{Component} from 'react';
import Boardings from './Boardings';
import Destinations from './Destinations';
import Modal from './Modal';
import Passengers from './Passengers';
import moment from 'moment';
import 'moment/locale/es'; 
import {Link} from 'react-router-dom';
export default class Searcher extends Component{
    constructor(props){
        super(props);
        this.state = {
            boarding:{
                id:0,
                denomination_short:'all',
                denomination_long:'todos los embarques',
                city_long:'paracas',
                country_long:'peru'
            },
            destination:{
                id:0,
                denomination_short:'all',
                denomination_long:'todos los destinos',
                city_long:'paracas',
                country_long:'peru'
            },
            pax:{
                adults:2,
                children:0,
                babies:0
            },
            departure:moment().add(1, 'days'),
            modalBoarding: false, 
            modalDestination:false,
            modalPassenger:false,
            modalDeparture:false,
        };
    }
    componentDidMount() {
        moment.locale('es');
      }
    updateDeparture = (newDate) => {
        this.setState({
          departure: moment(newDate),
          modalDeparture: false,
        });
      };
    updatePassenger = (selectedPax) => {
        this.setState({
          pax: selectedPax,
          modalPassenger: false,
        });
      }

    updateBoarding = (selectedBoarding) => {
        this.setState({
            boarding: selectedBoarding,
            modalBoarding: false,
        });
    }

    updateDestination = (selectedDestination) => {
        this.setState({
            destination: selectedDestination,
            modalDestination: false,
        })
    }
    
    toggleModalDeparture = () =>{
        this.setState({modalDeparture:!this.state.modalDeparture});
    }

    toggleModalPassenger = () => {
        this.setState({modalPassenger: !this.state.modalPassenger});
    }

    toggleModalBoarding = () => {
        this.setState({ modalBoarding: !this.state.modalBoarding });
    }

    toggleModalDestination = () => {
        this.setState({modalDestination: !this.state.modalDestination});
    }
    render(){
        const searchParams = new URLSearchParams();
        searchParams.append('boarding', this.state.boarding.denomination_short);
        searchParams.append('destination', this.state.destination.denomination_short);
        return (
            <>
                <div>
                    <div className="shadow-none bg-body-tertiary rounded">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column">
                                <div className="bg-white mt-1 mx-2 text-center p-3 hover"  onClick={this.toggleModalBoarding}>
                                    <p className="fs-3 fw-bold m-0 text-capitalize">{this.state.boarding.denomination_long}</p>
                                    <p className="fs-6 fw-light mt-n2 text-capitalize">{this.state.boarding.city_long}, {this.state.boarding.country_long}</p>
                                </div>
                                <div className="bg-white mt-2 mx-2 text-center p-3  hover" onClick={this.toggleModalDestination}>
                                    <p className="fs-3 fw-bold m-0 text-capitalize">{this.state.destination.denomination_long}</p>
                                    <p className="fs-6 fw-light mt-n2 text-capitalize">{this.state.destination.city_long}, {this.state.destination.country_long}</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-6 text-center mt-1">
                                    <div className="bg-white m-1 pt-3 pb-2 hover" onClick={this.toggleModalDeparture}>
                                        <p className="fs-3 mx-3 fw-bold m-0">{this.state.departure.format('D')} {this.state.departure.format('MMM')}</p>
                                        <p className="fs-6 mx-3 fw-light mt-n2">{this.state.departure.format('dddd')}</p>
                                    </div>
                                </div>
                                <div className="col-6 text-center mt-1">
                                    <div className="bg-white m-1 pt-3 pb-4 hover" onClick={this.toggleModalPassenger}>
                                        <p className="fs-6 mt-n2 mx-3 text-capitalize"><b>{this.state.pax.adults}</b> adultos, <b>{this.state.pax.children}</b> niños y <b>{this.state.pax.babies}</b> infantes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <Link to={`ballestas/search?boarding=${encodeURIComponent(this.state.boarding.denomination_short)}&destination=${encodeURIComponent(this.state.destination.denomination_short)}&adults=${encodeURIComponent(this.state.pax.adults)}&children=${encodeURIComponent(this.state.pax.children)}&babies=${encodeURIComponent(this.state.pax.babies)}&departure=${encodeURIComponent(this.state.departure.format('YYYY-MM-DD'))}`} className="btn btn-teal btn-block w-100 fs-1 font-quicksand"><i className="fa-solid fa-magnifying-glass fa-lg"></i>&nbsp;Buscar salida</Link>
                    </div>
                </div>
                {this.state.modalDeparture && (
                    <Modal onClose={this.toggleModalDeparture} selectedDate={this.state.departure} onUpdateDate={this.updateDeparture} />
                )}
                {this.state.modalBoarding && (
                    <Boardings onClose={this.toggleModalBoarding} onUpdateBoarding={this.updateBoarding} />
                )}
                {this.state.modalDestination && (
                    <Destinations onClose={this.toggleModalDestination} onUpdateDestination={this.updateDestination} />
                )}
                {this.state.modalPassenger && (
                    <Passengers onClose={this.toggleModalPassenger}  onUpdatePassenger={this.updatePassenger} pax={this.state.pax} />
                )}
            </>
        )
    }
}