import React from 'react';
import '../css/confirm.css';
export default function AlertComponent(props){
    return (
        <>
            <div className="confirm">
                <div className="confirm-content">
                    <div>{props.message}</div>
                    <div className="button-container">
                        <button className="btn btn-teal" onClick={(e)=>{
                           
                            props.onAccept()
                        }}>Aceptar</button>
                    </div>
                </div>
            </div>
        </>
    );
}