import React, { useState} from 'react';
import '../css/bottom-sheet.css';
function LoginBottomSheetVerifyCode(props) {

    const [code] = useState(props.code);
    const [user] = useState(props.user);
    const [inputValue, setInputValue] = useState('');

    const handleCode = (e) => {
        const inputText = e.target.value;
        const numericValue= inputText.replace(/\D/g, '');
        const limitedValue = numericValue.slice(0, 6);
        setInputValue(limitedValue);
    }

    const setOnClose = () => {
        props.onClose();
    }

    const onValidateCode = () =>{
        if(inputValue===code){
            user.cellPhoneValidated=true;
            props.onUpdateUser(user);
            props.onUpdateIsLoginBottomSheetPassword(true);
            setOnClose();
        }else{
            alert("codigo invalido");
        }
    }

    return (
     <>
      
      <div className="backdrop">
        <div className="bottom-sheet">
          <div className="d-flex justify-content-between">
            <div>&nbsp;</div>
            <p className="text-center fw-bold fs-6 col-10 text-uppercase p-2">Paso 2 - Valida tu código de seguridad</p>
            <div className="p-2" onClick={setOnClose}><i className="fa-solid fa-x"></i></div>
          </div>
          <div className="d-flex flex-column">
            <p className="text-muted fs-6 text-start">Hemos enviado un código de 6 dígitos al número de whatsapp: <b>+{user.cellPhonePostal+user.cellPhoneNumber}</b></p>
            <div className="mt-1">
                <input type="text" onChange={handleCode} className="form-control border" autoFocus />
            </div>
            <div className="mt-1">
                <a className="btn btn-teal w-100" onClick={onValidateCode}>Siguiente</a>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
  
  export default LoginBottomSheetVerifyCode;