import React,{Component} from 'react';
import Loading from './Loading';
import '../css/destination.css';
function Destination(props){
    
    return (
        <>
            <div onClick={props.onClick} >
                <div className="m-2 pt-1 pb-1 px-2 text-capitalize text-muted fs-6 d-flex align-items-center" ><i className="fw-bold text-black fa-solid fa-location-dot  fa-2x"></i>&nbsp;<small>{props.denomination}, {props.city}, {props.country}</small></div>
                <hr />
            </div>
        </>
    );
}
export default class Destinations extends Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            loading:false
        }
    }

    

    closeModal = () => {
        this.props.onClose(); // Llama a la función onClose pasada como prop
    }

    
    
    componentDidMount(){
        this.setState({loading:true});
        fetch("https://apis.gestionafacil.site/paracas.online/v1.0/destinations")
        .then(res=>res.json())
        .then(
            (json) => {
                this.setState({
                    items:json.response.data,
                    loading:false,
                });
            }
        )
    }
    render(){
        const {items} =this.state;
        return (
            <>
                {this.state.loading && <Loading />}
                <div className="destination">
                    <div className="destination-content">
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-start shadow-sm pt-2 pb-2 bg-body-tertiary rounded bg-white">
                                <div className="p-2 d-flex align-items-center" onClick={this.closeModal}><i className="fa-solid fa-arrow-left fa-2x color-yellow"></i></div>
                                <div className="p-2 fw-bold d-flex align-items-center">Selecciona el destino a visitar</div>
                            </div>
                            <div className="pt-3">
                                {items.map((el)=>(
                                    <Destination
                                        key={el.id}
                                        denomination={el.denomination_long}
                                        city={el.city_long}
                                        country={el.country_long}
                                        onClick={() => this.props.onUpdateDestination({
                                            id: el.id,
                                            denomination_short:el.denomination_short,
                                            denomination_long: el.denomination_long,
                                            city_short: el.city_short,
                                            city_long: el.city_long,
                                            country_short:el.country_short,
                                            country_long: el.country_long,
                                            latitude:el.latitude,
                                            longitude:el.longitude
                                            })}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) 
    }
}
